import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import { PrivateRoute } from "../components";
import ScrollToTop from "../components/ScrollToTop";

// Pages
// None Login
import Login from "./Auth/Login";
import SignUp from "./Auth/SignUp";
import PasswordReset from "./Auth/PasswordReset";

// Logined
import Home from "./Home";
import NotFound from "./NotFound";
import Brands from "./Brands";
import BrandDetail from "./BrandDetail";
import BrandProductDetail from "./BrandProductDetail";
import TermsCondition from "./Terms";
import Shop from "./Shop";
import ShopDetail from "./ShopDetail";
import Leaderboard from "./Leaderboard";
import Cart from "./Cart";
import Academy from "./Academy";
import AcademyDetail from "./AcademyDetail";
import Magazine from "./Magazine";
import MagazineDetail from "./MagazineDetail";
import Tv from "./Tv";
import Forum from "./Forum";
import OldSubjects from "./Forum/OldSubjects";
import Search from "./Search";
import Games from "./Games";
import GameFindMate from "./GameFindMate";
import GameQuestionWheel from "./GameQuestionWheel";
import GameWordPuzzles from "./GameWordPuzzles";
import GameGuessPicture from "./GameGuessPicture";
import GameScoreHunt from "./GameScoreHunt";
import StaticPage from "./StaticPage";
import { ROUTES } from "../constants/routes.const";
import Profile from "./Profile";

const Router = () => {
  const isAuthenticated = useSelector((store) => store.auth.isAuthenticated);

  return (
    <>
      <ScrollToTop />
      <Switch>
        <Route path="/login" component={Login} exact />
        <Route path="/signUp" component={SignUp} exact />
        <Route path="/passwordReset" component={PasswordReset} exact />

        <PrivateRoute
          path="/"
          component={Home}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/brands"
          component={Brands}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/brands/:id"
          component={BrandDetail}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/brand-product-detail/:brandSubCategoryId/:brandStyleId"
          component={BrandProductDetail}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/profile/:page"
          component={Profile}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/terms-and-conditions"
          component={TermsCondition}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/shop"
          component={Shop}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/shop/:id"
          component={ShopDetail}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/leaderboard"
          component={Leaderboard}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/cart"
          component={Cart}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/academy"
          component={Academy}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/academy/:topicId"
          component={AcademyDetail}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/magazine"
          component={Magazine}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/magazine/:id"
          component={MagazineDetail}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/tv"
          component={Tv}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/forum"
          component={Forum}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/forum-old-subjects"
          component={OldSubjects}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/search"
          component={Search}
          exact
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/search/:id"
          component={Search}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games"
          component={Games}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games/find-mate"
          component={GameFindMate}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games/question-wheel"
          component={GameQuestionWheel}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games/word-puzzles"
          component={GameWordPuzzles}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games/guess-picture"
          component={GameGuessPicture}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/games/score-hunt"
          component={GameScoreHunt}
          exact
          isAuthenticated={isAuthenticated}
        />

        <PrivateRoute
          path="/static/:slug"
          component={StaticPage}
          exact
          isAuthenticated={isAuthenticated}
        />

        <Route path={ROUTES.NOT_FOUND} component={NotFound} exact />
        <Route component={() => <Redirect to={ROUTES.NOT_FOUND} />} />
      </Switch>
    </>
  );
};

export default Router;
