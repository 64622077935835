/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, profileAction, forumAction } from "../../redux/modules";
import { NavLink } from "react-router-dom";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Toastify
import showMessage from "../../components/ShowMessage";
import showErrMessage from "../../components/ShowErrMessage";
import GlobalLoader from "../../components/GlobalLoader";

// Styles
import "./styles.scss";

// Format Date
import moment from "moment";
import "moment/locale/tr";
moment.locale("tr");

const Forum = () => {
  const dispatch = useDispatch();
  const forumWeeklyTopic = useSelector((store) => store.forum.forumWeeklyTopic);
  const isLoading = useSelector((store) => store.forum.isLoading);
  const forumComments = useSelector((store) => store.forum.forumComments);
  const profilePhoto = useSelector((store) => store.profile.profilePhoto);
  // const pageDescription = useSelector((store) => store.profile.pageDescription);

  const forumAllTopics = useSelector(store => store.forum.forumAllTopics);

  const [pageState, setPageState] = useState({
    isCommenting: false,
    newCommentLengthLeft: 500,
    comment: "",
    profilePhoto: null,
  });

  useEffect(() => {
    // Forum All Topics - Old Subjects
    dispatch(forumAction.getForumAllTopics());

    // Forum Weekly Topic
    dispatch(forumAction.getForumWeeklyTopic()).then((res) => {
      dispatch(forumAction.getForumComments(res.data.id));
    });

    // get profile photo
    dispatch(profileAction.getProfilePhoto());

    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (profilePhoto) {
      for (let i = 0; i < profilePhoto.length; i++) {
        if (
          profilePhoto[i].imageStatus === "APPROVED" &&
          profilePhoto[i].employeeImageContentType === "PROFILE"
        ) {
          setPageState({
            ...pageState,
            profilePhoto: profilePhoto[i].imageUrl
          });
        }
      }
    }

    return () => {};
  }, [profilePhoto]);

  const handleNewCommentChange = (e) => {
    e.preventDefault();

    setPageState({
      ...pageState,
      newCommentLengthLeft: 500 - (e.target.value).length,
      comment: e.target.value
    });
  };

  const handleCommentSubmit = (e, id) => {
    e.preventDefault();

    if (id && pageState?.comment && pageState?.newCommentLengthLeft < 500) {
      dispatch(forumAction.setForumCommentById(id, pageState?.comment))
      .then((res) => {
        console.log("forum comment by id: ", res);
        
        if (res.isHttpSuccess) {
          showMessage("Yorumunuz onaylandıktan sonra görüntülenecektir.");

          setPageState({
            ...pageState,
            newCommentLengthLeft: 500,
            comment: "",
            isCommenting: false
          });
        }
      })
      .catch((err) => {
        showErrMessage(err.message);
      });
    }
  };

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue pt-5 pb-lg-8 pb-5 mb-sm-5 mb-3">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-5 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              <span>Forum</span>
            </div>
            <div className="row justify-content-center mb-sm-5 mb-4">
              <div className="col-lg-6 col-md-8 text-center">
                <h6 className="mb-4 text-white opacity-50">Haftanın Konusu</h6>

                {forumWeeklyTopic?.title && 
                  <h4 className="mb-2 lh-base text-white">
                    {forumWeeklyTopic?.title}
                  </h4>
                }

                {forumWeeklyTopic?.description && 
                  <p className="mb-0 lh-base text-white">
                    {forumWeeklyTopic?.description}
                  </p>
                }
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-10">
                <div className="d-flex align-items-center">
                  
                  {pageState?.profilePhoto ? 
                    <img
                      src={pageState?.profilePhoto}
                      alt=""
                      className="rounded-circle shadow-sm me-3 d-sm-block d-none"
                      style={{width: '60px', height: '60px'}}
                    /> : <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold me-3" style={{width: '60px', height: '60px'}}>
                      <i className="fas fa-user h3 mb-0" />
                    </div>
                  }
                  
                  {forumWeeklyTopic?.title ? 
                    <form className="w-100" onSubmit={(e) => handleCommentSubmit(e, forumWeeklyTopic?.id)}>
                      <div className="commentInput bg-white shadow-lg rounded-3 py-4 px-sm-5 px-3 flex-grow-1 d-flex align-items-center justify-content-between">
                          {pageState.isCommenting ? (
                              <React.Fragment>
                                  <div className="w-100 text-end">
                                      <textarea className="form-control text-gray border-0 p-0" rows="4" placeholder="Mesajınız" name="message" maxLength="500" value={pageState?.comment} onChange={(e) => handleNewCommentChange(e)}></textarea>
                                      <div className="d-flex justify-content-between align-items-center mt-4">
                                          <span className={`small${pageState.newCommentLengthLeft <= 100 ? ' text-red' : ''}`}>{pageState.newCommentLengthLeft} harakter kaldı</span>
                                          <button type="submit" className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-4" disabled={pageState.newCommentLengthLeft < 500 ? false : true }>Gönder</button>
                                      </div>
                                  </div>
                                  <div className="d-none">
                                  {setTimeout(() => {
                                      document.getElementsByName('message')[0].focus()
                                  }, 100)}
                                  </div>
                              </React.Fragment>
                          ) : (
                              <React.Fragment>
                                  <h1 className="text-gray-light mb-0" onClick={(e) => {
                                      setPageState({
                                          ...pageState,
                                          isCommenting: true
                                      });
                                  }}>Düşünceni yaz…</h1>
                                  <div className="btn btn-blue rounded-pill py-sm-3 py-2 px-sm-4 px-3 ms-4 disabled">Gönder</div>
                              </React.Fragment>
                          )}
                      </div>
                    </form> : <h1 className="text-white">Haftanın konusu yakında belirlenecek</h1>
                  }

                </div>

                <p className="text-center mb-0 mt-5 small text-white opacity-50">
                  <i className="fas fa-comment me-2" />{forumWeeklyTopic?.views} kişi bu konu hakkında
                  konuşuyor.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="mb-sm-10 mb-5 mt-lg-0 mt-sm-5 mt-3">
          <div className="container-lg">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4 order-1">
                <div className="ps-lg-5 ps-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Son Konular</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      />
                    </div>
                    <div className="offcanvas-body">
                      <h4 className="mb-4 d-lg-block d-none">Son Konular</h4>
                      {/* Active Topic */}
                      {forumWeeklyTopic?.title && 
                        <a
                          href="#"
                          className="d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3"
                        >
                          <span
                            className="bg-orange text-white rounded-circle d-flex align-items-center justify-content-center flex-shrink-0 small"
                            style={{ width: 30, height: 30 }}
                          >
                            {forumWeeklyTopic?.likes}
                          </span>
                          <h6 className="mb-0 small fw-normal ms-3 text-green">
                            {forumWeeklyTopic?.title}
                          </h6>
                        </a>
                      }
                      {/* End Active Topic */}

                      {/* Old Topics */}
                      {forumAllTopics?.totalElements > 0 && forumAllTopics.content.map(topic => (
                        <a
                          href="#"
                          className="d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3"
                        >
                          <span
                            className="bg-orange text-white rounded-circle d-flex align-items-center justify-content-center flex-shrink-0 small"
                            style={{ width: 30, height: 30 }}
                          >
                            {topic?.likes}
                          </span>
                          <h6 className="mb-0 small fw-normal ms-3">
                            {topic?.title}
                          </h6>
                        </a>
                      ))}  
                      {/* End Old Topics */}
                    </div>
                  </div>
                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-list-alt" />
                  </a>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 order-0">
                <div className={forumComments?.totalElements > 0 ? "mt-lg-n8 mt-0 mb-n3" : ""}>
                  {forumComments?.totalElements > 0 ? forumComments?.content?.map(comment => (
                    <div className="shadow-lg bg-white rounded-3 mb-3">
                      <div className="row g-0">
                        <div className="col-3 p-xl-5 p-4 border-end text-center d-xl-block d-none">
                          <div className="d-flex align-items-center flex-column">
                            {comment.profilePic && comment.username ? 
                              <img
                                src={comment.profilePic}
                                alt={comment.username}
                                className="rounded-circle shadow mb-4 height-80"
                              /> 
                              : 
                              <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold mb-4" style={{width: '80px', height: '80px'}}>
                                <i className="fas fa-user h3 mb-0" />
                              </div>
                            }
                            
                            {comment.username &&
                              <h6 className="small mb-0">{comment.username}</h6>
                            }
                          </div>
                        </div>
                        <div className="col-xl-9 p-xl-5 p-4 position-relative d-flex flex-column justify-content-center">
                          
                          {comment.createDate &&
                            <span className="position-absolute top-0 end-0 small m-3">
                              {comment.createDate &&
                                moment(comment.createDate).format(
                                  "DD.MM.YYYY"
                                ) +
                                ", " +
                                moment(
                                  comment.createDate
                                ).format("dddd")
                              }
                            </span>
                          }
                          <div className="row align-items-center">
                            <div className="col lead fw-normal text-gray-darkest">
                              <div className="d-xl-none d-flex align-items-center mb-3">
                                {comment.profilePic && comment.username ? 
                                  <img
                                    src={comment.profilePic}
                                    alt={comment.username}
                                    className="rounded-circle flex-shrink-0 me-3 height-80"
                                  /> : <div className="bg-white shadow-sm rounded-circle d-flex justify-content-center align-items-center fw-bold mb-4" style={{width: '80px', height: '80px'}}>
                                    <i className="fas fa-user h3 mb-0" />
                                  </div>
                                }

                                {comment.username &&
                                  <h6 className="mb-0">{comment.username}</h6>
                                }
                              </div>
                              {comment.description && 
                                <p className="mb-0">
                                  {comment.description}
                                </p>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )): <div className="text-center">Henüz bir yorum yapılmadı.</div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />

      <GlobalLoader isLoading={isLoading} />
    </>
  );
};

export default Forum;
