import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { profileAction } from "../../../redux/modules";

import Monthly from "../../../components/Charts/Monthly";
import MonthlyIncome from "../../../components/Charts/MonthlyIncome";
import ProductFound from "../../../components/Charts/ProductFound";
import Percentage from "../../../components/Charts/Percentage";
import FocusInfo from "../../../components/Charts/FocusInfo";

import "./style.scss";

const Performance = () => {
  const dispatch = useDispatch();
  const monthlyBuy = useSelector(store => store.profile.monthlyBuy);
  const monthlyIncome = useSelector(store => store.profile.monthlyIncome);
  const productFound = useSelector(store => store.profile.productFound);
  const percentage = useSelector(store => store.profile.percentage);
  const focusInfo = useSelector(store => store.profile.focusInfo);

  const [activeTab, setActiveTab] = useState("performance-1");

  const handleGetMonthly = () => {
    dispatch(profileAction.getMonthlyPerformance());
  };

  const handleGetMonthlyIncome = () => {
    dispatch(profileAction.getMonthlyIncomePerformance());
  };

  // const handleGetProductFound = () => {
  //   dispatch(profileAction.getProductFoundPerformance());
  // };

  const handleGetPercentage = () => {
    dispatch(profileAction.getPercentagePerformance());
  };

  const handleGetFocusInfo = () => {
    dispatch(profileAction.getFocusInfoPerformance());
  };

  useEffect(() => {
    handleGetMonthly();
  }, []);

  const renderTabContent = () => {
    switch (activeTab) {
      case "performance-1":
        return monthlyBuy ? (
          <Monthly data={monthlyBuy} />
        ) : (
          <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
            Yükleniyor ...
          </div>
        );
      case "performance-2":
        return monthlyIncome ? (
          <MonthlyIncome data={monthlyIncome} />
        ) : (
          <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
            Yükleniyor ...
          </div>
        );
      case "performance-3":
        return productFound ? (
          <ProductFound data={productFound} />
        ) : (
          <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
            Yükleniyor ...
          </div>
        );
      case "performance-4":
        return percentage ? (
          <Percentage data={percentage} />
        ) : (
          <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
            Yükleniyor ...
          </div>
        );
      case "performance-5":
        return focusInfo ? (
          <FocusInfo data={focusInfo} />
        ) : (
          <div className="flex flex-col justify-center items-center min-h-[405px] !p-[20px]">
            Yükleniyor ...
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="performance-page tab-pane fade show active" role="tabpanel" aria-labelledby="accountinfo-tab">
      <div className="user-content-item">

        <ul className="nav nav-pills justify-content-sm-center mb-6" role="tablist">
          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "performance-1" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("performance-1");
                handleGetMonthly();
              }}
            >
              <span className="h6 mb-0">Aylık Ürün Alımı</span>
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "performance-2" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("performance-2");
                handleGetMonthlyIncome();
              }}
            >
              <span className="h6 mb-0">Aylık Kazanç Miktarı</span>
            </button>
          </li>

          {/* <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "performance-3" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("performance-3");
                handleGetProductFound();
              }}
            >
              <span className="h6 mb-0">Ürün Bulunurluk</span>
            </button>
          </li> */}

          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "performance-4" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("performance-4");
                handleGetPercentage();
              }}
            >
              <span className="h6 mb-0">Alım Miktarının Değişim Oranı</span>
            </button>
          </li>

          <li className="nav-item">
            <button
              className={`nav-link ${activeTab === "performance-5" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("performance-5");
                handleGetFocusInfo();
              }}
            >
              <span className="h6 mb-0">Focus Ürünlere Göre</span>
            </button>
          </li>
        </ul>

        <div className="tab-content tab-content-performance">{renderTabContent()}</div>

        <div className="user-content-text !mx-0 !mb-0 !mt-10">
          <p className="text-sm !px-0 !leading-5">
            Performans analizinizi sekmeler arasında gezerek inceleyebilirsiniz. Bilgilerinizde bir yanlışlık olduğunu düşünüyorsanız, lütfen 
            <Link to={`/profile/messages`} className="text-decoration-none text-nowrap">
              &nbsp;bize ulaşın.
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Performance;
