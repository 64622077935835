import React from "react";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";

const Terms = () => {
  return (
    <>
      <Header />
      <div className="profile-page static-page faq-page">
        <Container>
          <Row className="justify-content-md-center mt-5">
            <Col xs={12} md={10}>
              <Row>
                <Col>
                  <h1>ŞART VE KOŞULLAR</h1>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={3}>
                  <div className="detail-page-categories">
                    <ul>
                      <li className="active">
                        <a href="/about-us">Hizmet Koşulları</a>
                      </li>

                      <li>
                        <a href="#">Veri İlkesi</a>
                      </li>

                      <li>
                        <a href="#">Topluluk Standartları</a>
                      </li>

                      <li>
                        <a href="#">Çerezler</a>
                      </li>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} md={9}>
                  <div className="static-content">
                    <div className="faq-item">
                      <h3>Size kişiselleştirilmiş bir deneyim sunmak:</h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software
                      </p>
                    </div>

                    <div className="faq-item">
                      <h3>
                        Kendinizi ifade edebilmenizi ve önemsediğiniz şeyleri
                        aktarabilmenizi sağlamak:
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software
                      </p>
                    </div>

                    <div className="faq-item">
                      <h3>
                        İlgilenebileceğiniz içerik, ürün ve hizmetleri
                        keşfetmenize yardımcı olmak:
                      </h3>
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                        It was popularised in the 1960s with the release of
                        Letraset sheets containing Lorem Ipsum passages, and
                        more recently with desktop publishing software
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Terms;
