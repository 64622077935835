/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, magazineAction } from "../../redux/modules";

import { NavLink } from "react-router-dom";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// // React Bootstrap
// import Modal from "react-bootstrap/Modal";

// // FontAwesome
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTimes } from "@fortawesome/free-solid-svg-icons";

// // React Video Player
// import ReactPlayer from "react-player";

// Styles
import "./styles.scss";

const Tv = () => {
  const dispatch = useDispatch();
  // const pageDescription = useSelector((store) => store.profile.pageDescription);

  const magazineList = useSelector((store) => store.magazine.magazineList);
  const tvList = useSelector((store) => store.magazine.tvList);
  const tvCategoryList = useSelector(
    (store) => store.magazine.magazineCategoryList
  );

  // const [lgShow, setLgShow] = useState(false);
  // const [lgModalName, setLgModalName] = useState("");
  // const [lgModalVideoUrl, setLgModalVideoUrl] = useState("");
  // const [lgModalVideoType, setLgModalVideoType] = useState("");

  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    // Page Title and Description Call API!
    // dispatch(profileAction.getPageDescription("TV"));

    // TV
    dispatch(magazineAction.getArticleList("TV"));
    dispatch(magazineAction.getArticleCategoryList("TV"));

    // MAGAZINE
    dispatch(magazineAction.getArticleList("MAGAZINE", "5"));
    return () => {};
  }, [dispatch]);

  // const handleVideoType = (videoUrl) => {
  //   if (videoUrl.search("youtube") > 0) {
  //     setLgModalVideoType("youtube");
  //   } else if (videoUrl.search("dailymotion") > 0) {
  //     setLgModalVideoType("dailymotion");
  //   } else {
  //     setLgModalVideoType("normal");
  //   }
  // };

  // const handleOpenModal = (e, name, videoUrl) => {
  //   e.preventDefault();

  //   // Video Type
  //   handleVideoType(videoUrl);
  //   setLgModalVideoUrl(videoUrl);
  //   setLgModalName(name);
  //   setLgShow(true);
  // };

  const handleClickCategoryItem = (e, categoryId) => {
    e.preventDefault();

    setSelectedCategory(categoryId);

    dispatch(magazineAction.getMagazineCategoryById("TV", categoryId));
  };

  const handleSubCategoryClick = (e, subCategoryId, subCategoryName) => {
    e.preventDefault();

    if (subCategoryId === 0) {
      dispatch(magazineAction.getArticleList("TV"));
    } else {
      dispatch(magazineAction.getMagazineSubCategoryById("TV", subCategoryId));
    }
  };

  return (
    <div className="page-container">
      <Header />

      <main>
        <section className="bg-blue py-5 mb-sm-5 mb-3 d-lg-block d-none">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              <span>TV</span>
            </div>
          </div>
        </section>

        <section className="mb-sm-10 mb-5 mt-lg-0 mt-sm-5 mt-3">
          <div className="container">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4 order-1">
                <div className="ps-lg-5 ps-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Kategoriler</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <h4 className="mb-4 d-lg-block d-none">Kategoriler</h4>
                      <ul className="list-unstyled d-grid gap-3 mb-lg-8 mb-5">
                        {tvCategoryList?.content?.length > 0 &&
                          tvCategoryList.content.map((item) => (
                            <li key={item.id}>
                              <a
                                className="text-decoration-none"
                                onClick={(e) =>
                                  handleClickCategoryItem(e, item.id)
                                }
                              >
                                {item.name}
                              </a>

                              {selectedCategory &&
                              selectedCategory === item.id ? (
                                <>
                                  {item.topicSubCategoryList?.length > 0 &&
                                    item.topicSubCategoryList?.length > 0 && (
                                      <ul className="list-unstyled d-grid gap-3 mb-lg-2 mt-2 mb-2 mx-3">
                                        {item.topicSubCategoryList?.length >
                                          0 &&
                                          item.topicSubCategoryList.map(
                                            (subItem) => (
                                              <li key={subItem.id}>
                                                <a
                                                  onClick={(e) =>
                                                    handleSubCategoryClick(
                                                      e,
                                                      subItem.id,
                                                      subItem.name
                                                    )
                                                  }
                                                >
                                                  {subItem.name}
                                                </a>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    )}
                                </>
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                      </ul>

                      <h4 className="mb-4">Dergi</h4>
                      {magazineList?.content?.length > 0 &&
                        magazineList.content.map((item) => (
                          <NavLink
                            to={`/magazine/${item.id}`}
                            className="d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3"
                            key={item.id}
                          >
                            {item.articleContentDTOList &&
                            item.articleContentDTOList.length > 0 &&
                            item.articleContentDTOList[0].baseAddress ? (
                              <img
                                src={
                                  item?.articleContentDTOList[0]?.baseAddress
                                }
                                alt={item.name}
                                className="img-cover rounded-3"
                                style={{ width: "60px", height: "60px" }}
                              />
                            ) : (
                              <div
                                style={{
                                  width: "60px",
                                  height: "60px",
                                  background: "#ddd",
                                }}
                              ></div>
                            )}

                            <h6 className="mb-0 small fw-normal ms-3">
                              {item.name}
                            </h6>
                          </NavLink>
                        ))}
                    </div>
                  </div>
                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-list-alt"></i>
                  </a>
                </div>
              </div>
              <div className="col-xl-9 col-lg-8 order-0">
                {tvList?.content?.length > 0 &&
                  tvList.content.slice(0, 1).map((item) => (
                    <a
                      href={
                        item.videoArticleContent
                          ? item.videoArticleContent
                          : item.embededVideo
                      }
                      className="d-block overflow-hidden position-relative rounded-3 mb-sm-5 mb-3 mt-lg-n8 mt-0"
                      data-fancybox
                      key={item.id}
                    >
                      <div className="img-video bg-black">
                        {item.articleContentDTOList &&
                        item.articleContentDTOList.length > 0 &&
                        item.articleContentDTOList[0].baseAddress ? (
                          <img
                            src={item?.articleContentDTOList[0]?.baseAddress}
                            alt={item.name}
                            className="w-100 img-cover"
                            style={{ width: "960px", height: "480px" }}
                          />
                        ) : (
                          <div style={{ height: "480px", background: "#000" }}>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/img/logo.svg"
                              }
                              alt=""
                              className="w-100 opacity-50"
                              style={{
                                width: "960px",
                                height: "480px",
                                objectFit: "scale-down",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <div className="position-absolute bottom-0 w-100 p-sm-5 p-4 overlay">
                        <span className="d-block small text-white mb-3">
                          {item.dateCreated}{" "}
                          <span className="ms-3">
                            #{item.topicSubCategoryName}
                          </span>
                        </span>
                        <h2 className="mb-0 lh-base text-white">{item.name}</h2>
                      </div>
                    </a>
                  ))}

                <div className="row g-sm-4 g-2">
                  {tvList?.content?.length > 0 &&
                    tvList.content.slice(1, 9999).map((item) => (
                      <div className="col-xl-4 col-6" key={item.id}>
                        <a
                          href={
                            item.videoArticleContent
                              ? item.videoArticleContent
                              : item.embededVideo
                          }
                          className="d-block bg-white text-decoration-none shadow rounded-3 overflow-hidden h-100"
                          data-fancybox
                        >
                          <div className="img-video bg-black">
                            {item.articleContentDTOList &&
                            item.articleContentDTOList.length > 0 &&
                            item.articleContentDTOList[0].baseAddress ? (
                              <img
                                src={
                                  item?.articleContentDTOList[0]?.baseAddress
                                }
                                alt={item.name}
                                className="w-100 img-cover"
                                style={{ width: "300px", height: "200px" }}
                              />
                            ) : (
                              <div
                                style={{ height: "200px", background: "#000" }}
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/logo.svg"
                                  }
                                  alt=""
                                  className="w-100 opacity-50"
                                  style={{
                                    width: "300px",
                                    height: "200px",
                                    objectFit: "scale-down",
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="p-sm-4 p-3">
                            <h5 className="fw-normal lh-base mb-3">
                              {item.name}
                            </h5>
                            <div className="text-blue small">
                              <span className="me-2">
                                #{item.topicSubCategoryName}
                              </span>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Footer />

      {/* <Modal
        size="lg"
        show={lgShow}
        className="tvMagazineModal"
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <a
          className="magazine-modal-close-button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <Modal.Header>
          <Modal.Title id="example-modal-sizes-title-lg">
            {lgModalName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {lgModalVideoType === "youtube" ? (
            <ReactPlayer
              controls={true}
              url={lgModalVideoUrl}
              width={"100%"}
              height={"500px"}
            />
          ) : lgModalVideoType === "dailymotion" ? (
            <ReactPlayer
              controls={true}
              url={lgModalVideoUrl}
              width={"100%"}
              height={"500px"}
            />
          ) : (
            <ReactPlayer
              playing
              width="100%"
              height="auto"
              controls={true}
              url={[{ src: lgModalVideoUrl, type: "video/mp4" }]}
            />
          )}
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Tv;
