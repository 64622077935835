import { combineReducers } from "redux";

import about from "./about/about.reducer";
import auth from "./auth/auth.reducer";
import academy from "./academy/academy.reducer";
import home from "./home/home.reducer";
import profile from "./profile/profile.reducer";
import shop from "./shop/shop.reducer";
import cart from "./cart/cart.reducer";
import brand from "./brand/brand.reducer";
import order from "./order/order.reducer";
import product from "./product/product.reducer";
import magazine from "./magazine/magazine.reducer";
import forum from "./forum/forum.reducer";
import search from "./search/search.reducer";
import games from "./games/games.reducer";

const rootReducer = combineReducers({
  about,
  auth,
  academy,
  home,
  profile,
  shop,
  cart,
  brand,
  order,
  product,
  magazine,
  forum,
  search,
  games,
});

export default rootReducer;
