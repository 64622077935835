import * as authTypes from "./auth.type";

const initialState = {
  isAuthenticated: false,
  authUser: null,
  error: null,
  isLoading: false,
  employeeFirm: null,
  compList: null,
  forgotPassword: null,
  statisticsResult: null,
  refreshToken: null,
  logoutData: null,
  captchaBase64: null,
  announcementList: null,
  loginSettings: null,
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${authTypes.LOGIN_PENDING}`:
    case `${authTypes.REGISTER_PENDING}`:
    case `${authTypes.INIT_PASSWORD_PENDING}`:
    case `${authTypes.FORGOT_PASSWORD_PENDING}`:
    case `${authTypes.REGISTER_VENDOR_PENDING}`:
    case `${authTypes.REGISTER_VENDOR_SEND_CODE_PENDING}`:
    case `${authTypes.REGISTER_VENDOR_CONFIRM_PENDING}`:
    case `${authTypes.RETURN_EMPLOYEE_SCORE_PENDING}`:
    case `${authTypes.SET_COMPLIST_PENDING}`:
    case `${authTypes.SAVE_NEW_PASSWORD_PENDING}`:
    case `${authTypes.SAVE_STATISTICS_PENDING}`:
    case `${authTypes.REFRESH_TOKEN_PENDING}`:
    case `${authTypes.REGISTER_CONFIRM_SMS_CODE_PENDING}`:
    case `${authTypes.LOGOUT_PENDING}`:
    case `${authTypes.GET_CAPTCHA_PENDING}`:
    case `${authTypes.LOGIN_SETTINGS_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${authTypes.GET_LOGIN_ANNOUCEMENT}`:
      return {
        ...state,
        isLoading: false,
        announcementList: payload,
      };

    case `${authTypes.LOGIN_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        authUser: payload.data,
      };
    case `${authTypes.LOGIN_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isAuthenticated: false,
        authUser: null,
      };

    case `${authTypes.SET_COMPLIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        compList: payload,
      };
    case `${authTypes.SET_COMPLIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };

    case `${authTypes.FORGOT_PASSWORD_FULFILLED}`:
      console.log("payload: ", payload);

      return {
        ...state,
        isLoading: false,
        forgotPassword: payload,
      };

    case `${authTypes.FORGOT_PASSWORD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${authTypes.SAVE_NEW_PASSWORD_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        compList: payload.data,
      };
    case `${authTypes.SAVE_NEW_PASSWORD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${authTypes.SAVE_STATISTICS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        statisticsResult: payload.data,
      };
    case `${authTypes.SAVE_STATISTICS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        statisticsResult: null,
      };

    case `${authTypes.REFRESH_TOKEN_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        refreshToken: payload.data,
      };
    case `${authTypes.REFRESH_TOKEN_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        refreshToken: null,
      };

    case `${authTypes.GET_CAPTCHA_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        captchaBase64: payload,
      };
    case `${authTypes.GET_CAPTCHA_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        captchaBase64: null,
      };
    case `${authTypes.REGISTER_CONFIRM_SMS_CODE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        authUser: payload.data,
      };
    case `${authTypes.REGISTER_CONFIRM_SMS_CODE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
      };

    case `${authTypes.LOGIN_SETTINGS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        loginSettings: payload.data,
      };
    case `${authTypes.LOGIN_SETTINGS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        loginSettings: null,
      };

    case `${authTypes.REGISTER_FULFILLED}`:
    case `${authTypes.INIT_PASSWORD_FULFILLED}`:
    case `${authTypes.REGISTER_VENDOR_FULFILLED}`:
    case `${authTypes.REGISTER_VENDOR_SEND_CODE_FULFILLED}`:
    case `${authTypes.REGISTER_VENDOR_CONFIRM_FULFILLED}`:
      return { ...state, isLoading: false };

    case `${authTypes.REGISTER_REJECTED}`:
    case `${authTypes.INIT_PASSWORD_REJECTED}`:
    case `${authTypes.REGISTER_VENDOR_REJECTED}`:
    case `${authTypes.REGISTER_VENDOR_SEND_CODE_REJECTED}`:
    case `${authTypes.REGISTER_VENDOR_CONFIRM_REJECTED}`:
      return { ...state, isLoading: false, error: payload };

    case `${authTypes.LOGOUT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        logoutData: payload.data,
      };
    case `${authTypes.LOGOUT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        logoutData: null,
      };

    default:
      return state;
  }
};

export default reducer;
