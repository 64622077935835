import React from "react";
import PerformanceBanner from "../../../assets/images/performance/performance-banner-grey.jpg";

const Percentage = ({ data }) => {
  console.log("data: ", data.data);

  return (
    <>
      <div className="!p-3 text-center">
        Alım miktarınızın geçen sene aynı dönem ile kıyaslı durumu
        gösterilmektedir.
      </div>

      <div className="wrapper-performance-banner">
        <img
          src={PerformanceBanner}
          className="img-performance-banner"
          alt=""
        />

        {data?.data?.length > 0
          ? data?.data?.map((item, index) => (
              <div
                key={index}
                className="item-performance-banner"
              >
                <div className="">
                  <span>
                    + % {parseFloat(item.change).toFixed(1)}
                  </span>
                </div>
              </div>
            ))
          : "Data bulunamadı!"}
      </div>
    </>
  );
};

export default Percentage;
