import axios from "axios";
import { baseURL } from "../config/api.config";
import { ERROR_MESSAGES } from "../constants/messages.const";

let siteURL = window.location.origin;

// Force HTTPS for localhost in development
if (siteURL.startsWith("http://") && window.location.hostname === "localhost") {
  siteURL = siteURL.replace("http://", "https://");
}

// Create Axios instance
const API = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json",
    "X-Host": siteURL,
  },
  timeout: 60000,
});

// Request interceptor to add Authorization header
API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for error handling
API.interceptors.response.use(
  (response) => ({ ...response.data, isHttpSuccess: true }),
  (error) => {
    const status = error.response?.status || 0;
    const message =
      error.response?.data?.message || ERROR_MESSAGES[status] || ERROR_MESSAGES.default;

    // Handle Axios timeout
    if (error.code === "ECONNABORTED") {
      return Promise.reject({
        status: 504,
        message: ERROR_MESSAGES[504],
        error,
        isHttpError: true,
      });
    }

    return Promise.reject({ status, message, error, isHttpError: true });
  }
);

export { API };
