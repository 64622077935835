import * as cartTypes from "./cart.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Get Cart
export const getCart = (employeeFirmId) => async (dispatch) => {
  dispatch({ type: cartTypes.GET_CART_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/cart/get/cart`);
    dispatch({ type: cartTypes.GET_CART_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: cartTypes.GET_CART_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

export const addToCart =
  (id = 0, cartType, productId, productCount) =>
  async (dispatch) => {
    dispatch({ type: cartTypes.ADD_TO_CART_PENDING });

    const data = {
      id: id,
      cartType: cartType,
      productId: productId,
      productCount: productCount,
    };

    try {
      const response = await API.post(
        `${REQUEST_URL}/cart/add/product`,
        data
      );
      dispatch({
        type: cartTypes.ADD_TO_CART_FULFILLED,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: cartTypes.ADD_TO_CART_PENDING,
        payload: error,
      });
    }
  };

export const removeCartItemById = (id) => async (dispatch) => {
  dispatch({ type: cartTypes.REMOVE_CART_ITEM_BY_ID_PENDING });

  const data = {
    cartProductId: id,
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/cart/delete/product`,
      data
    );
    dispatch({
      type: cartTypes.REMOVE_CART_ITEM_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: cartTypes.REMOVE_CART_ITEM_BY_ID_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

export const removeAllItems = () => async (dispatch) => {
  try {
    const response = await API.post(`${REQUEST_URL}/cart/delete/cart`);
    dispatch({ type: cartTypes.REMOVE_ALL_ITEMS, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: cartTypes.REMOVE_ALL_ITEMS, payload: error });
    return Promise.reject(error);
  }
};
