import * as gamesTypes from './games.type';

import { API } from '../../../services/http.service';

const REQUEST_URL = '';

// Game Lucky Wheel
export const getLuckyWheel = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_LUCKY_WHEEL_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/game/luckyWheel`);
    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Control
export const getLuckyWheelControl = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_LUCKY_WHEEL_CONTROL_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/control/luckyWheel`);
    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_CONTROL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_CONTROL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Start
export const getLuckyWheelStart = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_LUCKY_WHEEL_START_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/start/luckyWheel`);
    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_START_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_LUCKY_WHEEL_START_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Lucky Wheel - Save Reward
export const setLuckyWheelReward = (luckyWheelId, luckyWheelType, prize, choiceId) => async dispatch => {
  dispatch({ type: gamesTypes.SET_LUCKY_WHEEL_PENDING });

  const data = {
    luckyWheelId: luckyWheelId,
    luckyWheelType: luckyWheelType,
    prize,
    choiceId: choiceId,
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/saveReward`, data);
    dispatch({
      type: gamesTypes.SET_LUCKY_WHEEL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gamesTypes.SET_LUCKY_WHEEL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Find Mate 
export const getFindMate = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_FIND_MATE_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/game/puzzleMatch`);
    dispatch({
      type: gamesTypes.GET_FIND_MATE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gamesTypes.GET_FIND_MATE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

//  Game Find Mate - Control
export const getFindMateControl = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_FIND_MATE_CONTROL_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/control/puzzleMatch`);
    dispatch({
      type: gamesTypes.GET_FIND_MATE_CONTROL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_FIND_MATE_CONTROL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Find Mate - Save
export const setFindMate = (id) => async dispatch => {
  dispatch({ type: gamesTypes.SET_FIND_MATE_PENDING });

  const data = {
    id: id
  };

  try {
    const response = await API.post(`${REQUEST_URL}/game/puzzleMatch/save`, data);
    dispatch({
      type: gamesTypes.SET_FIND_MATE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: gamesTypes.SET_FIND_MATE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};


// Game Hangman
export const getHangman = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/hangman`);
    dispatch({
      type: gamesTypes.GET_HANGMAN_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Hangman Control
export const getHangmanControl = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_CONTROL_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/control/hangman`);
    dispatch({
      type: gamesTypes.GET_HANGMAN_CONTROL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_CONTROL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Hangman Start
export const hangmanStart = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_START_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/start/hangman`);
    dispatch({
      type: gamesTypes.GET_HANGMAN_START_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_START_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Hangman CheckLetters
export const hangmanCheckLetters = (hangmanId, letters) => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_CHECK_LETTERS_PENDING });

  const data = {
    hangmanId: hangmanId,
    letters: letters, 
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/checkLetters`, data);
    dispatch({
      type: gamesTypes.GET_HANGMAN_CHECK_LETTERS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_CHECK_LETTERS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Hangman CheckOneLetter
export const hangmanCheckOneLetter = (hangmanId, letter) => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_CHECK_LETTER_PENDING });

  const data = {
    hangmanId: hangmanId,
    letter: letter,
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/checkOneLetter`, data);
    dispatch({
      type: gamesTypes.GET_HANGMAN_CHECK_LETTER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_CHECK_LETTER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Hangman Answer
export const hangmanSaveAnswer = (hangmanId, guessWord) => async dispatch => {
  dispatch({ type: gamesTypes.GET_HANGMAN_SAVE_ANSWER_PENDING });

  const data = {
    hangmanId: hangmanId,
    guessWord: guessWord,
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/saveHangmanAnswer`, data);
    dispatch({
      type: gamesTypes.GET_HANGMAN_SAVE_ANSWER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_HANGMAN_SAVE_ANSWER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Guess Picture
export const guessPicture = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_GUESS_PICTURE_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/guessPicture`);
    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Guess Picture Control
export const guessPictureControl = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_GUESS_PICTURE_CONTROL_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/control/guessPicture`);
    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_CONTROL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_CONTROL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Guess Picture Start
export const guessPictureStart = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_GUESS_PICTURE_START_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/start/guessPicture`);
    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_START_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_START_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Guess Picture Open Box
export const guessPictureOpenBox = (guessPictureId) => async dispatch => {
  dispatch({ type: gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/game/openBox/${guessPictureId}`);
    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_GUESS_PICTURE_OPEN_BOX_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Guess Picture Answer
export const guessPictureAnswer = (guessPictureId, choiceId, point) => async dispatch => {
  dispatch({ type: gamesTypes.SET_GUESS_PICTURE_ANSWER_PENDING });

  let data;

  if (choiceId) {
    data = {
      guessPictureId,
      choiceId,
      point
    }
  } else {
    data = {
      guessPictureId
    }
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/saveGuessAnswer`, data);
    dispatch({
      type: gamesTypes.SET_GUESS_PICTURE_ANSWER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.SET_GUESS_PICTURE_ANSWER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Score Hunt
export const scoreHunt = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_SCORE_HUNT_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/scoreHunt`);
    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Score Hunt Control
export const scoreHuntControl = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_SCORE_HUNT_CONTROL_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/control/scoreHunt`);
    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_CONTROL_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_CONTROL_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Score Hunt Start
export const scoreHuntStart = () => async dispatch => {
  dispatch({ type: gamesTypes.GET_SCORE_HUNT_START_PENDING });
  try {
    const response = await API.get(`${REQUEST_URL}/game/start/scoreHunt`);
    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_START_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.GET_SCORE_HUNT_START_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Game Score Hunt Answer
export const scoreHuntAnswer = (guessPictureId, choiceId) => async dispatch => {
  dispatch({ type: gamesTypes.SET_SCORE_HUNT_ANSWER_PENDING });

  let data;

  if (choiceId) {
    data = {
      guessPictureId,
      choiceId
    }
  } else {
    data = {
      guessPictureId
    }
  }

  try {
    const response = await API.post(`${REQUEST_URL}/game/save/scoreHunt`, data);
    dispatch({
      type: gamesTypes.SET_SCORE_HUNT_ANSWER_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    console.log("error: ", error.message);

    dispatch({
      type: gamesTypes.SET_SCORE_HUNT_ANSWER_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};