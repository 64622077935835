/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userAction, academyAction } from "../../redux/modules";

import ReactPlayer from "react-player";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Styles
import "./styles.scss";

const AcademyDetail = () => {
  const { topicId } = useParams();
  const dispatch = useDispatch();
  const [filteredAcademy, setFilteredAcademy] = useState(null);

  const academyList = useSelector((store) => store.academy.academyList);
  const academyDetailList = useSelector((store) => store.academy.academyDetailList);

  const [shownQuestions, setShownQuestions] = useState([]); // Gösterilen soruların ID'lerini takip eder
  const [currentTime, setCurrentTime] = useState(0); // Videonun mevcut zamanı
  const [showQuestion, setShowQuestion] = useState(false); // Sorunun görünürlük durumu
  const [activeItem, setActiveItem] = useState(null); 
  const [videoUrl, setVideoUrl] = useState(null); 
  const [isPlaying, setIsPlaying] = useState(true); // Videonun oynama durumu
  const playerRef = useRef(null); // ReactPlayer referansı


  // Soruların zamanları ve içerikleri
  const questions = [
    {
      id: 1, // Her soruya bir ID ekleyin
      time: 5,
      text: "Bu videoyla ilgili ne düşünüyorsunuz?",
      options: [
        { id: 1, text: "Harika", isCorrect: false },
        { id: 2, text: "İyi", isCorrect: false },
        { id: 3, text: "Orta", isCorrect: true },
        { id: 4, text: "Kötü", isCorrect: false },
      ],
    },
    {
      id: 2,
      time: 10,
      text: "İçerik yeterince açıklayıcı mıydı?",
      options: [
        { id: 1, text: "Evet", isCorrect: true },
        { id: 2, text: "Hayır", isCorrect: false },
        { id: 3, text: "Belki", isCorrect: false },
        { id: 4, text: "Hiçbir fikrim yok", isCorrect: false },
      ],
    },
    {
      id: 3,
      time: 15,
      text: "İçerik yeterince açıklayıcı mıydı 2 ?",
      options: [
        { id: 1, text: "Evet", isCorrect: true },
        { id: 2, text: "Hayır", isCorrect: false },
        { id: 3, text: "Belki", isCorrect: false },
        { id: 4, text: "Hiçbir fikrim yok", isCorrect: false },
      ],
    },
    {
      id: 4,
      time: 20,
      text: "İçerik yeterince açıklayıcı mıydı 3?",
      options: [
        { id: 1, text: "Evet", isCorrect: true },
        { id: 2, text: "Hayır", isCorrect: false },
        { id: 3, text: "Belki", isCorrect: false },
        { id: 4, text: "Hiçbir fikrim yok", isCorrect: false },
      ],
    },
  ];

  useEffect(() => {
    // Get Academy Detail List
    dispatch(academyAction.getAcademyList());
    dispatch(academyAction.getAcademyDetailList(topicId));

    return () => { };
  }, [dispatch, topicId]);

  useEffect(() => {
    if (academyList?.length > 0 && topicId) {
      const filteredData = academyList.filter((item) => item.id === parseInt(topicId));
      setFilteredAcademy(filteredData);
    }

    return () => { };
  }, [academyList, topicId]);


  useEffect(() => {
    const interval = setInterval(() => {
      if (playerRef.current) {
        const time = playerRef.current.getCurrentTime();
        setCurrentTime(time);
  
        // Zaman kontrolü ve sorunun daha önce gösterilip gösterilmediğini kontrol et
        const questionToShow = questions.find(
          (q) =>
            Math.floor(q.time) === Math.floor(time) &&
            !shownQuestions.includes(q.id) // Daha önce gösterilmediyse
        );
  
        if (questionToShow) {
          setShowQuestion(true); // Soruyu göster
          setIsPlaying(false); // Videoyu durdur
          setShownQuestions((prev) => [...prev, questionToShow.id]); // Gösterilen soruları güncelle
        }
      }
    }, 500);
  
    return () => clearInterval(interval); // Temizlik işlemi
  }, [questions, shownQuestions]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        event.code === "MediaPlayPause" || // Play/Pause tuşu
        event.code === "MediaTrackNext" || // İleri tuşu
        event.code === "MediaTrackPrevious" // Geri tuşu
      ) {
        event.preventDefault();
        event.stopPropagation();
      }
    };
  
    window.addEventListener("keydown", handleKeyDown);
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    const handlePlayPauseToggle = (event) => {
      if (event.code === "MediaPlayPause") {
        setIsPlaying((prev) => !prev); // Oynatma/Duraklatma kontrolü
      }
    };
  
    window.addEventListener("keydown", handlePlayPauseToggle);
  
    return () => {
      window.removeEventListener("keydown", handlePlayPauseToggle);
    };
  }, []);

  useEffect(() => {
    const blurPlayer = () => {
      if (playerRef.current) {
        playerRef.current.getInternalPlayer().blur(); // Odak başka bir yere taşınır
      }
    };
  
    document.addEventListener("visibilitychange", blurPlayer);
  
    return () => {
      document.removeEventListener("visibilitychange", blurPlayer);
    };
  }, []);

  useEffect(() => {
    if (academyDetailList && academyDetailList.length > 0) {
      console.log("academyDetailList: ", academyDetailList);
      if (academyDetailList[0].videoDetailDTOList && academyDetailList[0].videoDetailDTOList.length > 0) {

        // Default activeItem
        setActiveItem(academyDetailList[0].videoDetailDTOList[0]);

        if (academyDetailList[0].videoDetailDTOList[0]?.embeddedVideoUrl) {
          setVideoUrl(academyDetailList[0].videoDetailDTOList[0]?.embeddedVideoUrl);
        }

        if (academyDetailList[0].videoDetailDTOList[0]?.videoUrl) {
          setVideoUrl(academyDetailList[0].videoDetailDTOList[0]?.videoUrl);
        }
      }
    }

  }, [academyDetailList]);

  useEffect(() => {
    console.log("isPlaying: ", isPlaying);

  }, [isPlaying]);
   
  const handleAnswer = () => {
    setShowQuestion(false); // Soruyu gizle
    setIsPlaying(true); // Videoyu oynat
  };

  const handleChangeVideo = (item) => {
    console.log("item: ", item);
    setActiveItem(item);
    setIsPlaying(true);

    // Gösterilen soruların cache ini temizler
    setShownQuestions([]);
    
    if (item.embeddedVideoUrl) {
      setVideoUrl(item.embeddedVideoUrl);
    } else if (item.videoUrl) {
      setVideoUrl(item.videoUrl); 
    }
  }

  return (
    <>
      <Header />

      <main>
        <section className="bg-blue pt-5 pb-5">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-5 opacity-50" data-title-background>
              <NavLink to="/" className="text-white text-decoration-none me-3">Anasayfa</NavLink>
              <span className="me-3">»</span>
              <NavLink to="/academy" className="text-white text-decoration-none me-3">Akademi</NavLink>
              <span className="me-3">»</span>
              {filteredAcademy?.length > 0 && filteredAcademy[0]?.videoCategoryName &&
                <span className="text-white text-decoration-none">
                  {filteredAcademy[0].videoCategoryName}
                </span>
              }
            </div>

            {filteredAcademy?.length > 0 ?
              <div className="row justify-content-center mb-5" data-title-background>
                <div className="col-xl-12 col-lg-12 text-center">
                  {filteredAcademy[0]?.topicName && 
                    <h1 className="lh-base mb-4 text-white">{filteredAcademy[0]?.topicName}</h1>
                  }
                  
                  <div className="opacity-100 small text-white">
                    {filteredAcademy[0]?.description &&
                      <span className="text-white">
                        {filteredAcademy[0].description}
                      </span>
                    }
                  </div>
                </div>
              </div>
              : (
                <p>Academy bulunamadı yada yükleniyor...</p>
              )}
          </div>
        </section>

        <section className="position-relative py-5">
          <div className="container">

            <div className="row">
              <div className="col-12 col-md-8">
                {videoUrl && 
                  <ReactPlayer
                    width={'100%'}
                    ref={playerRef}
                    url={videoUrl}
                    playing={isPlaying}
                    controls={false}
                    tabIndex={-1} // Global medya erişimini sınırlandırır
                    playIcon={true}
                  />
                }

                {showQuestion && (
                  <div className="question-overlay">
                    {questions
                      .filter((q) => Math.floor(q.time) === Math.floor(currentTime))
                      .map((q, index) => (
                        <div key={index} className="question-box">
                          <p>{q.text}</p>
                          <div className="options">
                            {q.options.map((option) => (
                              <button
                                key={option.id}
                                onClick={handleAnswer}
                              >
                                {option.text}
                              </button>
                            ))}
                          </div>
                        </div>
                      ))}
                  </div>
                )}
              </div>

              <div className="col-12 col-md-4">
                {academyDetailList && 
                  academyDetailList.map(item => 
                    <ul key={item.subTopicId} className="academy-detail-topic-list">
                      <li>
                        {item.subTopicName && 
                          item.subTopicName
                        }

                        <ul className="academy-detail-subtopic-list">
                          {item.videoDetailDTOList && item.videoDetailDTOList.length > 0 && item.videoDetailDTOList.map(it => 
                            <li key={it.videoId}>
                              <button className={activeItem?.videoId === it?.videoId ? "active" : "" } onClick={() => handleChangeVideo(it)}>
                                {it.title}
                              </button>
                            </li>
                          )}
                        </ul>
                      </li>
                    </ul>
                  )
                }
              </div>

            </div>

          </div>
        </section>
      </main>

      <Footer />
    </>
  );
};

export default AcademyDetail;
