import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { productAction, brandAction } from "../../../redux/modules";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrophy,
} from "@fortawesome/free-solid-svg-icons";

const Favourites = () => {
  const dispatch = useDispatch();
  const productFavouriteList = useSelector((store) => store.product.productFavouriteList);
  const brandProductFavouriteList = useSelector((store) => store.brand.brandProductFavouriteList);

  useEffect(() => {
    dispatch(productAction.getProductFavouriteList());
    dispatch(brandAction.getBrandProductFavouriteList());
  }, [dispatch]);
  
  return (
    <React.Fragment>
      <h2 className="mb-sm-6 mb-4">Favorilerim</h2>

      <h5 className="text-blue mb-4 mb-sm-4">Dükkan Ürünleri</h5>
      <div className="row g-sm-4">
        {productFavouriteList && productFavouriteList?.productDTOList?.length > 0
          ? productFavouriteList?.productDTOList.map((item) => {
            return (
              <div className="col-xl-4 col-6"  key={item.id}>
                <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                  {item.productContentUrlList &&
                    item.productContentUrlList.length > 0 && item.name ? 
                      <img
                        src={
                          item.productContentUrlList[0]
                            .baseAddress
                        }
                        alt={item.name}
                        className="img-contain w-100"
                        style={{ height: '200px', margin: '20px 0' }}
                      />
                      : 
                      <div style={{ height: "200px", background: "#000" }}>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/img/logo.svg"
                          }
                          alt=""
                          className="w-100 opacity-50"
                          style={{
                            width: "310px",
                            height: "200px",
                            objectFit: "scale-down",
                          }}
                        />
                      </div>
                  }

                  <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                    {item.stock && 
                      <span className="d-block small text-blue">
                        {item.stock} stok
                      </span>
                    }

                    {item.name && 
                      <h5 className="fw-normal lh-base mb-2">
                        {item.name}
                      </h5>
                    }
                    
                    {item.score && 
                      <span className="d-block small text-blue mb-4">
                        {item.score} <FontAwesomeIcon icon={faTrophy} />
                      </span>
                    }

                    <Link
                      to={`/shop/${item.id}`}
                      // onClick={(e) => addToCart(e, item.id)}
                      data-id={`${item.id}`}
                      data-count={
                        item.productCartCount
                          ? item.productCartCount
                          : "0"
                      }
                      className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto add-to-cart-default"
                    >
                      <span>İncele</span>
                      <i className="fas fa-arrow-right ms-2 d-sm-inline-block d-none"></i>
                    </Link>
                  </div>
                </div>
              </div>
            )
          }) : <div>Favoriye herhangi bir ürün eklenmedi.</div>}
      </div>

      <h5 className="text-blue mb-4 mb-sm-4 mt-5">Marka Ürünleri</h5>
      <div className="row g-sm-4">
        {brandProductFavouriteList && brandProductFavouriteList?.brandStyleDTOList?.length > 0
          ? brandProductFavouriteList.brandStyleDTOList.map((item, index) => {
            return (
              <div className="col-xl-4 col-6"  key={item.id} id={item.id + "" + index}>
                <div className="bg-white text-decoration-none shadow overflow-hidden rounded-3 h-100 d-flex flex-column">
                  {item.brandStyleContentDTOList &&
                    item.brandStyleContentDTOList.length > 0 && item.name ? 
                      <img
                        src={
                          item.brandStyleContentDTOList[0]
                            .baseAddress
                        }
                        alt={item.name}
                        className="img-contain w-100"
                        style={{ height: '200px', margin: '20px 0' }}
                      />
                      : 
                      <div style={{ height: "200px", background: "#000" }}>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/img/logo.svg"
                          }
                          alt=""
                          className="w-100 opacity-50"
                          style={{
                            width: "310px",
                            height: "200px",
                            objectFit: "scale-down",
                          }}
                        />
                      </div>
                  }

                  <div className="p-sm-4 p-3 flex-grow-1 d-flex flex-column">
                    {item.stock && 
                      <span className="d-block small text-blue">
                        {item.stock} stok
                      </span>
                    }

                    {item.name && 
                      <h5 className="fw-normal lh-base mb-2">
                        {item.name}
                      </h5>
                    }
                    
                    {item.score && 
                      <span className="d-block small text-blue mb-4">
                        {item.score} <FontAwesomeIcon icon={faTrophy} />
                      </span>
                    }

                    <Link
                      to={`/brand-product-detail/${item.brandSubCategoryId}/${item.id}`}
                      // onClick={(e) => addToCart(e, item.id)}
                      data-id={`${item.id}`}
                      data-count={
                        item.productCartCount
                          ? item.productCartCount
                          : "0"
                      }
                      className="btn btn-green text-white w-100 rounded-pill py-sm-3 py-2 px-sm-4 px-3 text-nowrap mt-auto add-to-cart-default"
                    >
                      <span>İncele</span>
                      <i className="fas fa-arrow-right ms-2 d-sm-inline-block d-none"></i>
                    </Link>
                  </div>
                </div>
              </div>
            )
          }) : <div>Favoriye herhangi bir ürün eklenmedi.</div>}
      </div>
      
    </React.Fragment>
  );
};

export default Favourites;
