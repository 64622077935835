/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, magazineAction } from "../../redux/modules";

import { NavLink } from "react-router-dom";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Styles
import "./styles.scss";

const Magazine = () => {
  const dispatch = useDispatch();

  const magazineList = useSelector((store) => store.magazine.magazineList);
  const magazineCategoryList = useSelector(
    (store) => store.magazine.magazineCategoryList
  );

  const tvList = useSelector((store) => store.magazine.tvList);

  const [selectedCategory, setSelectedCategory] = useState(0);

  useEffect(() => {
    // MAGAZINE
    dispatch(magazineAction.getArticleList("MAGAZINE"));
    dispatch(magazineAction.getArticleCategoryList("MAGAZINE"));

    // TV
    dispatch(magazineAction.getArticleList("TV", "5"));

    return () => {};
  }, [dispatch]);

  const handleClickCategoryItem = (e, categoryId) => {
    e.preventDefault();

    setSelectedCategory(categoryId);
    dispatch(magazineAction.getMagazineCategoryById("MAGAZINE", categoryId));
  };

  const handleSubCategoryClick = (e, subCategoryId) => {
    e.preventDefault();

    if (subCategoryId === 0) {
      dispatch(magazineAction.getArticleList("MAGAZINE"));
    } else {
      dispatch(magazineAction.getMagazineSubCategoryById("MAGAZINE", subCategoryId));
    }
  };

  return (
    <div className="page-container">
      <Header />

      <main>
        <section className="bg-blue py-5 mb-sm-5 mb-3 d-lg-block d-none">
          <div className="container">
            <div className="d-lg-flex d-none text-white small mb-4 opacity-50">
              <NavLink to="/" className="text-white text-decoration-none me-3">
                Anasayfa
              </NavLink>
              <span className="me-3">»</span>
              {/* <span className="me-3">Bizim Dünya</span>
              <span className="me-3">»</span> */}
              <span>Dergi</span>
            </div>
          </div>
        </section>

        <section className="mb-sm-10 mb-5 mt-lg-0 mt-sm-5 mt-3">
          <div className="container">
            <div className="row gy-0">
              <div className="col-xl-3 col-lg-4 order-1">
                <div className="ps-lg-5 ps-0">
                  <div className="offcanvas offcanvas-start offcanvas-responsive-lg">
                    <div className="offcanvas-header">
                      <h5 className="offcanvas-title">Kategoriler</h5>
                      <button
                        type="button"
                        className="btn-close text-reset"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="offcanvas-body">
                      <h4 className="mb-4 d-lg-block d-none">Kategoriler</h4>
                      <ul className="list-unstyled d-grid gap-3 mb-lg-8 mb-5">
                        {magazineCategoryList?.content?.length > 0 && magazineCategoryList.content.filter(item => item.active === true).map((item) => (
                          <li key={item.id}>
                            <a
                              className="text-decoration-none"
                              onClick={(e) => handleClickCategoryItem(e, item.id)}
                            >
                              {item.name}
                            </a>

                            {selectedCategory && selectedCategory === item.id ? 
                              <>
                                {item.topicSubCategoryList?.length > 0 &&
                                  item.topicSubCategoryList.filter(subItem => subItem.active === true)?.length > 0 && 
                                  <ul className="list-unstyled d-grid gap-3 mb-lg-2 mt-2 mb-2 mx-3">
                                    {item.topicSubCategoryList?.length > 0 &&
                                      item.topicSubCategoryList.filter(subItem => subItem.active === true).map(subItem => (
                                        <li key={subItem.id}>
                                          <a onClick={(e) => handleSubCategoryClick(e, subItem.id)}>
                                            {subItem.name}
                                          </a>
                                        </li>
                                      ))
                                    }
                                  </ul>
                                }
                              </> : ""
                            }

                          </li>
                        ))}
                      </ul>

                      <h4 className="mb-4">TV</h4>
                      {tvList?.content?.length > 0 ? (
                        tvList.content.map((item) => (
                          <a
                            href={item.videoArticleContent ? item.videoArticleContent : item.embededVideo}
                            className="d-flex align-items-center text-reset text-decoration-none border-bottom pb-3 mb-3"
                            data-fancybox
                            key={item.id}
                          >
                            {item.articleContentDTOList?.length > 0 && item.articleContentDTOList[0].baseAddress ? 
                              <img
                                src={item.articleContentDTOList[0].baseAddress}
                                alt={item.name}
                                className="img-contain rounded-3"
                                style={{ width: "60px", height: "60px" }}
                              />
                            : <img
                                src={process.env.PUBLIC_URL + "/assets/img/logo.svg"}
                                alt={item.name}
                                className="img-contain rounded-3"
                                style={{ width: "60px", height: "60px" }}
                              />
                            }
                            
                            <h6 className="mb-0 small fw-normal ms-3">
                              {item.name}
                            </h6>
                          </a>
                        ))
                      ) : (
                        <p>TV içeriği bulunmamaktadır!</p>
                      )}

                    </div>
                  </div>
                  <a
                    href=".offcanvas"
                    className="btn btn-blue-light text-white rounded-circle p-0 d-flex align-items-center justify-content-center shadow position-fixed bottom-0 end-0 mb-3 me-3"
                    data-bs-toggle="offcanvas"
                  >
                    <i className="fas fa-list-alt"></i>
                  </a>
                </div>
              </div>
              
              <div className="col-xl-9 col-lg-8 order-0">
                {magazineList?.content?.length > 0 && magazineList.content.slice(0, 1)
                  .map((item) => (
                    <NavLink
                      to={`/magazine/${item.id}`}
                      className="d-block overflow-hidden position-relative rounded-3 mb-sm-5 mb-3 mt-lg-n8 mt-0"
                      key={item.id}
                    >
                      {item.articleContentDTOList && item.articleContentDTOList.length > 0 && item.articleContentDTOList[0].baseAddress ?
                        <img
                          src={
                            item?.articleContentDTOList[0]?.baseAddress
                          }
                          alt={item.name}
                          className="w-100 img-cover"
                          style={{ width: "960px", height: "480px" }}
                        /> : <div style={{ height: '480px', background: '#ddd' }}></div>
                      }

                      <div className="position-absolute bottom-0 w-100 p-sm-5 p-4 overlay">
                        <span className="d-block small text-white mb-3">
                          {item.dateCreated}{" "}
                          <span className="ms-3">
                            #
                            {

                            }
                          </span>
                        </span>
                        <h2 className="mb-0 lh-base text-white">{item.name}</h2>
                      </div>
                    </NavLink>
                  ))}

                <div className="mb-sm-n4 mb-n2">
                  {magazineList?.content?.length > 0 && magazineList.content.slice(1, 9999)
                    .map((item) => (
                      <NavLink
                        to={`/magazine/${item.id}`}
                        className="d-block overflow-hidden position-relative rounded-3 shadow text-decoration-none text-reset mb-sm-4 mb-2"
                        key={item.id}
                      >
                        <div className="row g-0 align-items-stretch">
                          <div className="col-md-5">
                            {item.articleContentDTOList && item.articleContentDTOList.length > 0 && item.articleContentDTOList[0].baseAddress ? 
                              <img
                                src={
                                  item?.articleContentDTOList[0]?.baseAddress
                                }
                                alt={item.name}
                                className="img-cover w-100 h-100"
                                style={{ width: "300px", height: "200px" }}
                              /> : <div style={{ width: "100%", height: "100%", background: '#ddd' }}></div>
                            }
                          </div>
                          <div className="col-md-7 px-sm-5 px-4 py-4 align-self-center">
                            <span className="d-block text-blue small mb-1">
                              {item.dateCreated}
                            </span>
                            <h5 className="mb-3 lh-base">{item.name}</h5>
                            <p className="fw-light small mb-0 d-xl-block d-none">
                              {item.description
                                .replace(/<\/?[^>]+(>|$)/g, "")
                                .slice(0, 100)}
                              ...
                            </p>
                            <span className="d-block text-blue small mt-4">
                              {
                                item.topicSubCategoryName && `#${item.topicSubCategoryName}`
                              }
                            </span>
                          </div>
                        </div>
                      </NavLink>
                    ))}
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default Magazine;
