import * as orderTypes from "./order.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

// Get Cart
export const createOrder = (
  cartType,
  categoryType,
  deliveryAddressId,
  description
) => async dispatch => {
  dispatch({ type: orderTypes.CREATE_ORDER_PENDING });
  const data = {
    cartType: cartType,
    categoryType: categoryType,
    deliveryAddressId: deliveryAddressId,
    employeeFirmId: 1, // TODO: Will be remove
    description: description
  };

  try {
    const response = await API.post(
      `${REQUEST_URL}/order/create/order`,
      data
    );
    dispatch({ type: orderTypes.CREATE_ORDER_FULFILLED, payload: response });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({ type: orderTypes.CREATE_ORDER_REJECTED, payload: error });
    return Promise.reject(error);
  }
};

// cancelOrder
export const cancelOrder = (orderId) => async dispatch => {
  dispatch({ type: orderTypes.CANCEL_ORDER_PENDING });

  try {
    const response = await API.post(
      `${REQUEST_URL}/order/cancel/order/${orderId}`
    );
    dispatch({
      type: orderTypes.CANCEL_ORDER_FULFILLED,
      payload: response
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: orderTypes.CANCEL_ORDER_REJECTED,
      payload: error
    });
    return Promise.reject(error);
  }
};