import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction, profileAction } from "../../../redux/modules";

// Toastify
import showMessage from "../../../components/ShowMessage";
import showErrMessage from "../../../components/ShowErrMessage";

const Address = () => {
  const dispatch = useDispatch();
  const address = useSelector((store) => store.profile.address);

  const [validated, setValidated] = useState(false);
  const countries = useSelector((store) => store.profile.countryList);
  const provinces = useSelector((store) => store.profile.provinceList);
  const counties = useSelector((store) => store.profile.countyList);
  const districts = useSelector((store) => store.profile.districtList);

  // Form Datas
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [county, setCounty] = useState("");
  const [district, setDistrict] = useState("");
  const [province, setProvince] = useState("");
  const [street, setStreet] = useState("");
  const [apartmentNo, setApartmentNo] = useState("");
  const [flatNo, setFlatNo] = useState("");
  const [editAddress, setEditAddress] = useState(false);
  const [selectedAddressId, setSelectedAddressId] = useState(null);
  const [addNewAddressButtonActive, setAddNewAddressButtonActive] = useState(true);

  const selectCountry = (event) => {
    setCountry(event.target.value);
    setProvince(""); // İl seçimini sıfırla

    setCounty(""); // İlçe seçimini sıfırla
    dispatch(profileAction.resetAddressCounty());

    setDistrict(""); // Mahalle seçimini sıfırla
    dispatch(profileAction.resetAddressDistrict());

    dispatch(profileAction.getAddressProvince(event.target.value));
  };

  const selectProvince = (event) => {
    setProvince(event.target.value);
    setCounty(""); // İlçe seçimini sıfırla
    setDistrict(""); // Mahalle seçimini sıfırla
    dispatch(profileAction.resetAddressDistrict());
    dispatch(profileAction.getAddressCounty(event.target.value));
  };

  const selectCounty = (event) => {
    setCounty(event.target.value);
    setDistrict(""); // Mahalle seçimini sıfırla
    dispatch(profileAction.getAddressDistrict(event.target.value));
  };

  const selectDistrict = (event) => {
    setDistrict(event.target.value);
  };

  const resetForm = () => {
    setName("");
    setCountry("");
    setCounty("");
    setDistrict("");
    setProvince("");
    setStreet("");
    setApartmentNo("");
    setFlatNo("");
    setEditAddress(false);
    setSelectedAddressId(null);
    setAddNewAddressButtonActive(true);
  };

  const handleSaveAddress = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      dispatch(
        profileAction.addAddress({
          name,
          country,
          province,
          county,
          district,
          street,
          apartmentNo,
          flatNo,
        })
      )
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage("Yeni Adres Eklendi!");
            resetForm();
            dispatch(profileAction.getAddress());
          }
        })
        .catch((err) => {
          showErrMessage(err.message);
          setValidated(false);
        });
    }
  };

  const handleEditAddress = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setValidated(true);

    const form = event.currentTarget;

    if (form.checkValidity() !== false) {
      dispatch(
        profileAction.addAddress({
          name,
          country,
          province,
          county,
          district,
          street,
          apartmentNo,
          flatNo,
          addressId: selectedAddressId,
        })
      )
        .then((e) => {
          if (e.isHttpSuccess) {
            showMessage("Adres Güncellendi!");
            resetForm();
            dispatch(profileAction.getAddress());
          }
        })
        .catch((err) => {
          showErrMessage(err.message);
          setValidated(false);
        });
    }
  };

  const handleDeleteAddress = (e, addressId) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(profileAction.deleteAddress(addressId))
      .then((e) => {
        if (e.isHttpSuccess) {
          showMessage("Adres Silindi!");
          dispatch(profileAction.getAddress());
        }
      })
      .catch((err) => {
        showErrMessage(err.message);
      });
  };

  const changeAddressStatus = (e, addressId) => {
    e.preventDefault();

    dispatch(profileAction.changeAddressStatus(addressId))
      .then((e) => {
        if (e.isHttpSuccess) {
          showMessage("Sipariş Adresi Güncellendi!");
          dispatch(profileAction.getAddress());
        }
      })
      .catch((err) => {
        showErrMessage(err.message);
      });
  };

  const handleEditClick = (event, addressId) => {
    event.preventDefault();
    setEditAddress(true);
    setAddNewAddressButtonActive(false);
    setSelectedAddressId(addressId);

    const selectedAddress = address.find((addr) => addr.addressId === addressId);

    if (selectedAddress) {
      setName(selectedAddress.name);
      setCountry(selectedAddress.countryId);
      dispatch(profileAction.getAddressProvince(selectedAddress.countryId));
      setProvince(selectedAddress.provinceId);
      dispatch(profileAction.getAddressCounty(selectedAddress.provinceId));
      setCounty(selectedAddress.countyId);
      dispatch(profileAction.getAddressDistrict(selectedAddress.countyId));
      setDistrict(selectedAddress.districtId);
      setStreet(selectedAddress.street);
      setApartmentNo(selectedAddress.apartmentNo);
      setFlatNo(selectedAddress.flatNo);
    }
  };

  useEffect(() => {
    // Get All Address
    dispatch(profileAction.getAddress());
    dispatch(profileAction.getAddressCountry());

    return () => {};
  }, [dispatch]);

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-sm-6 mb-4">
        <h2 className="mb-0">Adres Bilgilerim</h2>
        {addNewAddressButtonActive ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none"
            onClick={(event) => {
              setAddNewAddressButtonActive(false);
              setEditAddress(false);
            }}
          >
            <i className="fas fa-plus-circle"></i> Yeni Adres Ekle
          </button>
        ) : null}
      </div>
      <p className="mb-sm-5 mb-4 fw-light">
        Adres alanına girdiğiniz bilgiler, hediye siparişlerinizin gönderimi
        için kullanılacağından bilgilerinizin doğruluğundan lütfen emin olunuz.
        Ev ya da işyeri adresi gibi birden fazla adres girişi yapabilirsiniz.
        Yalnızca siparişinizin gönderilmesini istediğiniz adresi "Sipariş
        Adresi" olarak seçmeniz gerekmektedir. Sipariş vermeden önce mutlaka
        doğru adresin sipariş adresi olarak seçildiğini kontrol etmelisiniz.
      </p>

      {addNewAddressButtonActive === false ? (
        <form onSubmit={editAddress ? handleEditAddress : handleSaveAddress}>
          <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-4">
            <h5 className="text-blue mb-0">
              {editAddress ? "Adresi Düzenle" : "Yeni Adres Ekle"}
            </h5>
            <button
              type="button"
              className="btn btn-link text-red text-decoration-none"
              onClick={() => {
                resetForm();
              }}
            >
              {addNewAddressButtonActive === false ? (
                <small>
                  <i className="fas fa-times me-2"></i>Vazgeç
                </small>
              ) : null}
            </button>
          </div>
          <div className="row row-cols-md-3 row-cols-1 gy-4 pb-sm-5 pb-4 mb-sm-5 mb-4">
            <div className="col">
              <div className="form-floating">
                <input
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Adres Adı"
                  maxLength="20"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                <label>Adres Adı</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <select
                  name="country"
                  className="form-select"
                  required
                  value={country}
                  onChange={(e) => {
                    selectCountry(e);
                  }}
                >
                  <option value="">Seçiniz</option>
                  {countries
                    ? countries.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                <label>Ülke</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <select
                  name="province"
                  className="form-select"
                  required
                  value={province}
                  onChange={(e) => {
                    selectProvince(e);
                  }}
                >
                  <option value="">Seçiniz</option>
                  {provinces
                    ? provinces.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                <label>Şehir</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <select
                  name="county"
                  className="form-select"
                  required
                  value={county}
                  onChange={(e) => selectCounty(e)}
                >
                  <option value="">Seçiniz</option>
                  {counties
                    ? counties.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                <label>İlçe</label>
              </div>
            </div>

            <div className="col">
              <div className="form-floating">
                <select
                  name="district"
                  className="form-select"
                  required
                  value={district}
                  onChange={(e) => selectDistrict(e)}
                >
                  <option value="">Seçiniz</option>
                  {districts
                    ? districts.map((item, i) => (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      ))
                    : null}
                </select>
                <label>Mahalle</label>
              </div>
            </div>

            <div className="col">
              <div className="form-floating">
                <input
                  type="text"
                  name="street"
                  className="form-control"
                  placeholder="Cadde/Sokak"
                  maxLength="100"
                  required
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
                <label>Cadde/Sokak</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <input
                  type="text"
                  name="apartmentNo"
                  className="form-control"
                  placeholder="Apartman No/Adı"
                  maxLength="100"
                  required
                  value={apartmentNo}
                  onChange={(e) => setApartmentNo(e.target.value)}
                />
                <label>Apartman No/Adı</label>
              </div>
            </div>
            <div className="col">
              <div className="form-floating">
                <input
                  type="number"
                  name="flatNo"
                  className="form-control"
                  placeholder="Apartman No/Adı"
                  maxLength="100"
                  required
                  value={flatNo}
                  onChange={(e) => setFlatNo(e.target.value)}
                />
                <label>Daire No</label>
              </div>
            </div>

            <div className="col">
              <input
                type="hidden"
                name="addressId"
                defaultValue={editAddress && selectedAddressId ? selectedAddressId : ""}
              />
              <button
                type="submit"
                className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3 w-100"
              >
                {editAddress ? "Düzenle" : "Ekle"}
              </button>
            </div>
          </div>
        </form>
      ) : null}

      {address && address.length > 0 ? (
        <React.Fragment>
          <h5 className="text-blue mb-sm-5 mb-4">Adreslerim</h5>
          <div className="row row-cols-xl-3 row-cols-md-2 row-cols-1 gy-4">
            {address.map((item, i) => (
              <div className="col" key={item.addressId}>
                <div className="h-100 bg-white shadow-lg rounded-3 p-4 position-relative">
                  <h6
                    className="text-capitalize text-truncate"
                    style={{ maxWidth: "90%" }}
                  >
                    {item.name}
                  </h6>
                  <p className="mb-0">
                    {item.countyName} / {item.provinceName}
                  </p>
                  <div className="mt-4 d-flex align-items-center">
                    <button
                      style={{ padding: 0, outline: 'none' }}
                      type="button"
                      className="btn btn-link text-decoration-none small"
                      onClick={(event) => handleEditClick(event, item.addressId)}
                    >
                      <i className="fas fa-edit me-2"></i>Düzenle
                    </button>
                    {item?.orderAddress ? (
                      <span className="small text-green ms-auto">
                        <i className="fas fa-check-circle me-2"></i>Sipariş
                        Adresi
                      </span>
                    ) : (
                      <a
                        href="#"
                        className="text-decoration-none small ms-auto"
                        onClick={(event) =>
                          changeAddressStatus(event, item.addressId)
                        }
                      >
                        <i className="fas fa-circle fw-light me-2"></i>Sipariş
                        Adresi
                      </a>
                    )}
                  </div>
                  <button
                    type="button"
                    className="btn btn-link text-decoration-none text-red position-absolute top-0 end-0 m-2"
                    onClick={(event) =>
                      handleDeleteAddress(event, item.addressId)
                    }
                  >
                    <i className="fas fa-trash-alt small"></i>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

export default Address;
