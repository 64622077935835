/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  authAction,
  profileAction,
  shopAction,
  searchAction,
} from "../../../redux/modules";

//components
import Modal from ".././../../components/Modal";

//style
import style from "./Header.module.scss";

// jQuery
import $ from "jquery";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faShoppingCart,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  const dispatch = useDispatch();
  const authUser = useSelector((store) => store.auth.authUser);
  const loginSettings = useSelector((store) => store.auth.loginSettings);
  const profilePhoto = useSelector((store) => store.profile.profilePhoto);
  const profileDetail = useSelector((store) => store.profile.profileDetail);
  const cartData = useSelector((store) => store.cart.cartData);
  const searchData = useSelector((store) => store.search.searchData);
  const pointHistoryAll = useSelector((store) => store.profile.pointHistoryAll);

  const [profilePhotoItem, setProfilePhotoItem] = useState(null);
  const [responseItem, setResponseItem] = useState(0);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false); // State to control modal visibility
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const [score, setScore] = useState(profileDetail?.score || 0); // Local state to track score

  const [menuItems, setMenuItems] = useState([
    { id: 1, title: "ÜRÜNLERİMİZ", url: "/brands" },
    {
      id: 2,
      title: "OYUNLAR",
      url: "/games",
      subMenu: [
        { id: 200, title: "EŞİNİ BUL", url: "/games/find-mate" },
        { id: 201, title: "KELİME BULMACA", url: "/games/word-puzzles" },
        { id: 202, title: "PUAN AVI", url: "/games/score-hunt" },
        { id: 203, title: "RESMİ TAHMİN ET", url: "/games/guess-picture" },
        { id: 204, title: "SORU ÇARKI", url: "/games/question-wheel" },
      ],
    },
    { id: 3, title: "DÜKKAN", url: "/shop" },
    {
      id: 4,
      title: "BİZİM DÜNYA",
      subMenu: [
        // { id: 100, title: "AKADEMİ", url: "/academy" },
        { id: 101, title: "DERGİ", url: "/magazine" },
        { id: 102, title: "FORUM", url: "/forum" },
        { id: 103, title: "SIRALAMA", url: "/leaderboard" },
        { id: 104, title: "TV", url: "/tv" },
      ],
    },
  ]);

  const infoModalMenuItems = useMemo(
    () => [
      { id: 1, title: "Hesap Bilgilerim", url: "/profile/account-info" },
      { id: 2, title: "Adres Bilgilerim", url: "/profile/address" },
      { id: 3, title: "Mesajlarım", url: "/profile/messages" },
      { id: 4, title: "Fotoğraflarım", url: "/profile/photos" },
      { id: 5, title: "Puan Geçmişim", url: "/profile/scores" },
      { id: 6, title: "Siparişlerim", url: "/profile/orders" },
      { id: 7, title: "Favorilerim", url: "/profile/favourites" },
      { id: 8, title: "Performans", url: "/profile/performance" },
      { id: 9, title: "Dashboard", url: "/profile/dashboard" },
      { id: 10, title: "İrsaliye", url: "/profile/waybill" },
    ],
    []
  );

  useEffect(() => {
    // profileDetail değiştiğinde score'u güncelle
    if (profileDetail?.score) {
      setScore(profileDetail.score);
    }
  }, [profileDetail]);

  const handleModalOpen = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  //Secure Logout
  const logout = () => {
    dispatch(
      authAction.logout(
        localStorage.getItem("token"),
        localStorage.getItem("refreshToken")
      )
    ).then((res) => {
      if (res.isHttpSuccess) {
        setTimeout(function () {
          localStorage.clear();
          window.location.reload();
        }, 500);
      }
    }).catch(err => {
      if (err.isHttpError) {
        setTimeout(function () {
          localStorage.clear();
          window.location.replace('/login');
        }, 500);
      }
    });
  };

  useEffect(() => {
    dispatch(profileAction.getProfilePhoto());
    dispatch(profileAction.getLoginUserDetail());
    dispatch(shopAction.getCart());
    dispatch(profileAction.getProfileAllPointHistory());
    return () => {};
  }, [dispatch]);

  useEffect(() => {
    if (authUser) {
      dispatch(profileAction.getTenantMenu(authUser?.roleId))
        .then(res => {
          // res.data var mı diye kontrol ediyoruz
          if (res.isHttpSuccess && Array.isArray(res.data)) {
            // res.data içinde item ve item.menu var mı diye kontrol ediyoruz
            const tenantPaths = res.data.map(item => item?.menu?.pathUrl || '');
  
            const filteredMenu = menuItems.map(menuItem => {
              if (menuItem.subMenu && Array.isArray(menuItem.subMenu)) {
                menuItem.subMenu = menuItem.subMenu.filter(subItem => !tenantPaths.includes(subItem.url));
              }
              return tenantPaths.includes(menuItem.url) ? null : menuItem;
            }).filter(item => item !== null);
  
            setMenuItems(filteredMenu);
          }
        })
        .catch(error => {
          console.log("Tenant menüsü yüklenirken hata oluştu:", error);
        });
    }
  
    return () => {};
  }, [authUser]);  

  useEffect(() => {
    if (
      cartData &&
      cartData.cartProductDTOList &&
      cartData.cartProductDTOList.length > 0
    ) {
      var res = 0;
      for (const item of cartData.cartProductDTOList) {
        res += item.productCount;
      }
      setResponseItem(res);
    } else {
      setResponseItem(0);
    }

    return () => {};
  }, [cartData]);

  useEffect(() => {
    if (profilePhoto) {
      for (let i = 0; i < profilePhoto.length; i++) {
        if (
          profilePhoto[i].imageStatus === "APPROVED" &&
          profilePhoto[i].employeeImageContentType === "PROFILE"
        ) {
          setProfilePhotoItem(profilePhoto[i]);
        }
      }
    }

    return () => {};
  }, [profilePhoto]);

  useEffect(() => {
    $('[data-toggle="offcanvas"]').on("click", function () {
      $(".offcanvas-collapse").toggleClass("open");
      $(".offcanvas-overlay").toggleClass("open");
      $(".page-content").toggleClass("blur");
      $(".user-avatar").toggleClass("blur");
    });

    $(".offcanvas-overlay").on("click", function () {
      $(".offcanvas-collapse").toggleClass("open");
      $(".offcanvas-overlay").toggleClass("open");
      $(".page-content").toggleClass("blur");
      $(".user-avatar").toggleClass("blur");
    });

    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (search.length > 2) {
        dispatch(searchAction.getSearch(search));
        setIsSearchOpen(true);
      } else {
        setIsSearchOpen(false);
      }
    }, 600); // 600ms gecikme süresi

    return () => clearTimeout(delayDebounceFn);
  }, [search, dispatch]);

  const formatDateString = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("-");
    return `${day}.${month}.${year}`;
  };

  return (
    <div className="sticky-top bg-white shadow-lg">
      <header>
        {/* <div className="alert alert-dismissible border-0 bg-blue-lighter text-blue-light small text-center mb-0 fade show">
          <i className="fas fa-info-circle me-2" />
          Yeni yarışma yayınlandı. Detayları incelemek için{" "}
          <a href="#" className="text-reset">
            tıklayın
          </a>
          .
          <button type="button" className="btn-close" data-bs-dismiss="alert" />
        </div> */}

        <div className="search-container d-flex justify-content-center pt-3 position-relative">
          <div className="d-flex justify-content-center pt-3 w-50">
            <input
              className="border-0 bg-gray-lighter rounded-pill px-4 py-2 text-gray-darker w-50"
              placeholder="Hadi aradığını bulalım..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            ></input>
          </div>

          {isSearchOpen && searchData?.length > 0 && (
            <div className="search-popup w-25 position-absolute bg-white shadow-sm rounded-3">
              {searchData?.map((item) => (
                <a
                  key={item.id}
                  href={item.resultPageURL}
                  className="d-flex justify-content-between align-items-center p-2 text-decoration-none"
                >
                  <p className="mb-0">{item.name}</p>
                  <p className="mb-0">{item.tableName}</p>
                </a>
              ))}
            </div>
          )}
        </div>

        <nav className="navbar navbar-expand-xl">
          <div className="container-xxl">
            <a href="/" className="navbar-logo">
              {loginSettings?.logo && 
                <img src={loginSettings?.logo} alt="logo-header" />
              }

              <div className={style.role}>
                {authUser?.employeeFirm?.role && authUser?.employeeFirm?.role}
              </div>
            </a>

            <div className="order-xl-2 order-1 col-xl-2 ms-auto">
              <div className="flex justify-content-end align-items-center mb-2">
                <Link to="/" className="profile-icon me-2">
                  <FontAwesomeIcon
                    icon={faBell}
                    className="text-gray-special"
                  />
                </Link>

                <Link to="/cart" className="profile-icon position-relative">
                  <FontAwesomeIcon
                    icon={faShoppingCart}
                    className="text-gray-special"
                  />

                  {responseItem && responseItem > 0 ? (
                    <span className="shopping-cart-count">
                      {responseItem && responseItem > 0 && responseItem}
                    </span>
                  ) : null}
                </Link>
              </div>

              <a
                href="#"
                className="d-flex justify-content-end align-items-center text-decoration-none"
                data-bs-toggle="modal"
                data-bs-target="#modalUser"
                onClick={(e) => handleModalOpen(e)}
              >
                {profilePhotoItem?.imageUrl ? (
                  <img
                    src={profilePhotoItem?.imageUrl}
                    alt="user-profile"
                    className="shadow-sm rounded-circle"
                    style={{ height: 48 }}
                  />
                ) : (
                  <div
                    className="shadow-sm rounded-circle bg-gray d-flex flex-col justify-content-center align-items-center"
                    style={{ height: 48, width: 48 }}
                  >
                    <FontAwesomeIcon
                      icon={faUser}
                      className="text-gray-special"
                      style={{ width: 24, height: 24, color: "#fff" }}
                    />
                  </div>
                )}
                <div className="ms-3">
                  <h6 className="mb-0">
                    {profileDetail?.employeeDTO?.name}{" "}
                    {profileDetail?.employeeDTO?.surname}
                  </h6>
                  <span className="text-green small fw-bold">{score} Puan</span>
                </div>
              </a>
            </div>

            <button
              className="navbar-toggler collapsed order-xl-3 order-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse"
            >
              <span />
              <span />
              <span />
            </button>

            <div className="collapse navbar-collapse order-xl-1 order-3 col-xl-auto">
              <ul className="navbar-nav gap-xl-5 gap-4 mx-auto py-xl-0 py-4 pb-2">
                {menuItems.map((item) => (
                  <li
                    key={item.id}
                    className={`nav-item ${item.subMenu ? "dropdown" : ""}`}
                  >
                    {item.subMenu ? (
                      <>
                        <a
                          href="#"
                          className="nav-link text-gray-darker p-0 d-flex align-items-center"
                        >
                          {item.title}{" "}
                          <i className="fas fa-caret-down small ms-2"></i>
                        </a>
                        <ul className="dropdown-menu border-0 shadow-sm rounded-3">
                          {item.subMenu.map((subItem) => (
                            <li key={subItem.id}>
                              <a href={subItem.url} className="dropdown-item">
                                {subItem.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </>
                    ) : (
                      <a
                        className="nav-link text-gray-darker p-0"
                        href={item.url}
                      >
                        {item.title}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </nav>
      </header>

      {showModal && (
        <Modal onClose={handleModalClose} isOpen={showModal}>
          <div className={style.userInfoModalContent}>
            <div className={style.infoModalMenuItems}>
              {infoModalMenuItems.map((item) => (
                <a key={item?.id} href={item?.url}>
                  {item?.title}
                </a>
              ))}
              <span onClick={() => logout()}>Çıkış</span>
            </div>
            <div className={style.infoModalScoreSection}>
              <p className={style.score}>
                {profileDetail?.score} <span>Puan</span>
              </p>
              <div className={style.latestScoreInfo}>
                {pointHistoryAll?.content
                  ?.slice(-3)
                  .map((item) => (
                    <div key={item.id}>
                      <p>
                        <span>{formatDateString(item?.dateCreated)} </span>
                        {item?.scoreTypeName}
                      </p>
                      <div className={style.changedScore}>
                        {item?.changedScore} puan
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Header;
