import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { profileAction } from "../../../redux/modules";
import showMessage from "../../../components/ShowMessage";
import showErrMessage from "../../../components/ShowErrMessage";

import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

const Waybill = () => {
  const dispatch = useDispatch();
  const profilePhoto = useSelector((store) => store.profile.profilePhoto);
  const [loading, setLoading] = useState(false);

  const [pageState, setPageState] = useState({
    isNewPhotoVisible: false,
    newImage: null,
    newImageFile: null, // Add this to store the file object
    newImageType: "WAYBILL",
  });

  const [state, setState] = useState({
    isLoading: false,
    isError: false,
    error: null,
  });

  const handleImageUpload = (file, type) => {
    setState({
      ...state,
      isLoading: true,
    });
    setLoading(true);

    dispatch(profileAction.imageUpload(file, type)).then((res) => {
      if (res.isHttpSuccess) {
        let imageType = null;
        if (type === "WAYBILL") {
          imageType = "İrsaliye";
        } else {
          imageType = "Diğer";
        }
        showMessage(
          `${imageType} başarıyla yüklendi. Bekleme sekmesinden kontrol edebilirsiniz. Onaylandıktan sonra işleme alınacaktır!`
        );
        dispatch(profileAction.getProfilePhoto());
        // Reset states
        setPageState({
          ...pageState,
          isNewPhotoVisible: false,
          newImage: null,
          newImageFile: null,
          newImageType: "WAYBILL",
        });
      } else {
        showErrMessage("İrsaliye yüklenirken bir hata oluştu.");
      }
      setLoading(false);
      setState({ ...state, isLoading: false });
    });
  };

  const handleImageRemove = (e, imageId) => {
    e.preventDefault();

    dispatch(profileAction.removeImageById(imageId))
      .then((e) => {
        showMessage("Görsel başarılı bir şekilde silindi!");
        dispatch(profileAction.getProfilePhoto());
      })
      .catch((err) => {
        showErrMessage(err.message);
      });
  };

  const handleImageChange = (event) => {
    event.preventDefault();
    if (event.target.files?.[0]) {
      setPageState({
        ...pageState,
        newImage: window.URL.createObjectURL(event.target.files[0]),
        newImageFile: event.target.files[0], // Store the file object
      });
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center justify-content-between mb-sm-6 mb-4">
        <h2 className="mb-0">İrsaliyelerim</h2>
        {!pageState.isNewPhotoVisible ? (
          <button
            type="button"
            className="btn btn-link text-decoration-none"
            onClick={(event) => {
              setPageState({
                ...pageState,
                isNewPhotoVisible: true,
              });
            }}
          >
            <i className="fas fa-plus-circle"></i> Yeni İrsaliye Ekle
          </button>
        ) : null}
      </div>
      <p className="mb-sm-5 mb-4 fw-light">
        Bu bölümde irsaliyelerinizi ekleyebilir veya
        güncelleyebilirsiniz. İrsaliyelerinizin onaylanabilmesi için, lütfen
        net olarak göründüğü bir irsaliye seçin. Yüklediğiniz irsaliyeler için
        onay mesajını bir hafta içinde Mesajlarım bölümünde görebilirsiniz.
        İrsaliyeleriniz ile ilgili mesaj almadığınız takdirde “Bize Ulaşın” formunu
        doldurarak veya 0850 532 5787 Connection Çağrı Merkezi’ni
        arayarak detaylı bilgi alabilirsiniz.
      </p>

      {pageState.isNewPhotoVisible ? (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleImageUpload(pageState.newImageFile, pageState.newImageType);
          }}
        >
          <div className="d-flex align-items-center justify-content-between mb-sm-5 mb-4">
            <h5 className="text-blue mb-0">Yeni İrsaliye Ekle</h5>

            <button
              type="button"
              className="btn btn-link text-red text-decoration-none"
              onClick={(event) => {
                setPageState({
                  ...pageState,
                  isNewPhotoVisible: false,
                  newImage: null,
                  newImageFile: null,
                  newImageType: "PROFILE",
                });
              }}
            >
              <small>
                <i className="fas fa-times me-2"></i>Vazgeç
              </small>
            </button>
          </div>

          <div className="row gy-4 gx-5 align-items-center pb-sm-5 pb-4 mb-sm-5 mb-4">
            <div className="col-12 col-md-9 col-lg-8">

              <div className="form-floating">
                <Form.Row className="mb-4">
                  <Form.Group controlId="irsaliye-tarihi">
                    <Form.Label>İrsaliye Tarihi</Form.Label>
                    <Form.Control
                      type="date"
                      defaultValue=""
                      required
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Row className="mb-4">
                  <Form.Group controlId="urun-kategorisi">
                    <Form.Label>Ürün Kategorisi</Form.Label>
                    <Form.Control
                      as="select"
                      defaultValue=""
                      required
                    >
                      <option value="">Seçiniz</option>
                      <option>
                        Ürün Kategorisi 1
                      </option>

                      <option>
                        Ürün Kategorisi 2
                      </option>

                      <option>
                        Ürün Kategorisi 3
                      </option>

                      <option>
                        Ürün Kategorisi 4
                      </option>
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
              </div>

              <div
                className="shadow-lg rounded-3 position-relative overflow-hidden w-100 d-flex align-items-center justify-content-center"
                style={{ height: "300px" }}
              >
                <input
                  type="file"
                  className="position-absolute w-100 h-100 top-0 start-0 opacity-0"
                  style={{ cursor: "pointer" }}
                  accept="image/*"
                  name="file"
                  onInput={(event) => handleImageChange(event)}
                />
                {pageState.newImage ? (
                  <React.Fragment>
                    <img
                      src={pageState.newImage}
                      alt=""
                      className="w-100 h-100 img-contain position-absolute top-0 start-0"
                    />
                    <button
                      type="button"
                      className="btn btn-link text-white bg-red rounded-circle m-3 text-decoration-none position-absolute top-0 end-0"
                      onClick={(event) => {
                        setPageState({
                          ...pageState,
                          newImage: null,
                          newImageFile: null,
                        });
                      }}
                    >
                      <i className="fas fa-trash-alt"></i>
                    </button>
                  </React.Fragment>
                ) : null}
                <div className="text-center">
                  <h3 className="text-green">
                    <i className="fas fa-plus-circle"></i>
                  </h3>
                  <h5 className="text-green">İrsaliye Yükle</h5>
                </div>
              </div>

              <div
                onChange={(event) => {
                  setPageState({
                    ...pageState,
                    newImageType: event.target.value,
                  });
                }}
              >
                <div className="form-check mb-4">
                  <input
                    className="form-check-input"
                    type="hidden"
                    name="type"
                    value="WAYBILL"
                    id="WAYBILL"
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-blue py-sm-3 py-2 px-sm-4 px-3"
                disabled={pageState.newImage && !state.isLoading ? false : true}
              >
                {state.isLoading ? (
                  <span className="spinner-border spinner-border-sm me-3"></span>
                ) : null}
                Gönder
              </button>
            </div>
          </div>
        </form>
      ) : null}

      {profilePhoto ? (
        <div className="row row-cols-md-3 row-cols-2 align-items-center">
          {profilePhoto.filter((item) => item.employeeImageContentType === 'WAYBILL').map((item, i) => (
            <div className="col" key={i}>
              <img
                src={item.imageUrl}
                alt=""
                className="img-contain w-100 mb-2 rounded-3"
              />
              {item.imageStatus === "DENIED" ? (
                <span className="text-red small">
                  <i className="fas fa-times-circle me-2"></i>Reddedildi
                </span>
              ) : item.imageStatus === "WAITING" ? (
                <span className="text-orange small">
                  <i className="fas fa-clock fw-light me-2"></i>Beklemede
                </span>
              ) : (
                <span className="text-green small">
                  <i className="fas fa-check-circle fw-light me-2"></i>Onaylandı
                </span>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center mt-8">
          <h1 className="mb-3 opacity-75">
            <i className="fas fa-image"></i>
          </h1>
          <h3 className="mb-4 opacity-75">Hiç irsaliye yok</h3>
        </div>
      )}
    </React.Fragment>
  );
};

export default Waybill;
