import * as profileTypes from "./profile.type";

const initialState = {
  profileDetail: null,
  address: null,
  deleteAddressResponse: null,
  changeAddressStatusResponse: null,
  selectedAddress: null,
  countryList: null,
  provinceList: null,
  countyList: null,
  districtList: null,
  subjectList: null,
  subjectDetail: null,
  sendMessage: null,
  changePassword: null,
  pageDescription: null,
  profileImageUpload: null,
  pointHistoryAll: null,
  pointHistoryCollected: null,
  pointHistorySpent: null,
  profilePhoto: null,
  responseImageUpload: null,
  orderList: [],
  tenantMenu: null,
  score: null,
  monthlyBuy: null,
  monthlyIncome: null,
  productFound: null,
  percentage: null,
  focusInfo: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${profileTypes.GET_PROFILE_DESCRIPTION_PENDING}`:
    case `${profileTypes.SET_CHANGE_PASSWORD_PENDING}`:
    case `${profileTypes.SET_ADDRESS_PENDING}`:
    case `${profileTypes.GET_ADDRESSES_PENDING}`:
    case `${profileTypes.DELETE_ADDRESS_PENDING}`:
    case `${profileTypes.SET_ADDRESS_STATUS_PENDING}`:
    case `${profileTypes.GET_COUNTRY_PENDING}`:
    case `${profileTypes.GET_PROVINCE_PENDING}`:
    case `${profileTypes.GET_COUNTY_PENDING}`:
    case `${profileTypes.GET_DISTRICT_PENDING}`:
    case `${profileTypes.GET_SUBJECT_LIST_PENDING}`:
    case `${profileTypes.GET_SUBJECT_BY_ID_PENDING}`:
    case `${profileTypes.SET_SEND_MESSAGE_PENDING}`:
    case `${profileTypes.GET_LOGIN_USER_DETAIL_PENDING}`:
    case `${profileTypes.SET_PROFILE_IMAGE_UPLOAD_PENDING}`:
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_PENDING}`:
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_PENDING}`:
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_PENDING}`:
    case `${profileTypes.GET_PROFILE_USER_IMAGE_PENDING}`:
    case `${profileTypes.REMOVE_IMAGE_PENDING}`:
    case `${profileTypes.GET_ORDER_LIST_PENDING}`:
    case `${profileTypes.GET_USERS_SCORE_PENDING}`:
    case `${profileTypes.GET_TENANT_MENU_PENDING}`:
    case `${profileTypes.GET_MONTHLY_PERFORMANCE_PENDING}`:
    case `${profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_PENDING}`:
    case `${profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_PENDING}`:
    case `${profileTypes.GET_PERCENTAGE_PERFORMANCE_PENDING}`:
    case `${profileTypes.GET_FOCUS_INFO_PERFORMANCE_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${profileTypes.GET_PROFILE_DESCRIPTION_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        pageDescription: payload.data
      };
    case `${profileTypes.GET_PROFILE_DESCRIPTION_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        pageDescription: null
      };

    case `${profileTypes.GET_LOGIN_USER_DETAIL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        profileDetail: payload.data
      };
    case `${profileTypes.GET_LOGIN_USER_DETAIL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        profileDetail: null
      };

    case `${profileTypes.SET_ADDRESS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        selectedAddress: payload.data
      };
    case `${profileTypes.SET_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        selectedAddress: null
      };

    case `${profileTypes.GET_ADDRESSES_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        address: payload.data
      };
    case `${profileTypes.GET_ADDRESSES_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        address: null
      };

    case `${profileTypes.DELETE_ADDRESS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        deleteAddressResponse: payload.data
      };
    case `${profileTypes.DELETE_ADDRESS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        deleteAddressResponse: null
      };

    case `${profileTypes.SET_ADDRESS_STATUS_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        changeAddressStatusResponse: payload.data
      };
    case `${profileTypes.SET_ADDRESS_STATUS_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        changeAddressStatusResponse: null
      };

    case `${profileTypes.GET_COUNTRY_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        countryList: payload.data
      };
    case `${profileTypes.GET_COUNTRY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        countryList: null
      };

    case `${profileTypes.GET_PROVINCE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        provinceList: payload.data
      };
    case `${profileTypes.GET_PROVINCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        provinceList: null
      };

    case `${profileTypes.GET_COUNTY_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        countyList: payload.data
      };
    case `${profileTypes.GET_COUNTY_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        countyList: null
      };

    case `${profileTypes.GET_DISTRICT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        districtList: payload.data
      };
    case `${profileTypes.GET_DISTRICT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        districtList: null
      };

    case `${profileTypes.GET_SUBJECT_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        subjectList: payload.data
      };
    case `${profileTypes.GET_SUBJECT_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        subjectList: null
      };

    case `${profileTypes.GET_SUBJECT_BY_ID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        subjectDetail: payload.data
      };
    case `${profileTypes.GET_SUBJECT_BY_ID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        subjectDetail: null
      };

    case `${profileTypes.SET_SEND_MESSAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        sendMessage: payload.data
      };
    case `${profileTypes.SET_SEND_MESSAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        sendMessage: null
      };

    case `${profileTypes.SET_CHANGE_PASSWORD_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        changePassword: payload.data
      };
    case `${profileTypes.SET_CHANGE_PASSWORD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        changePassword: null
      };

    case `${profileTypes.SET_PROFILE_IMAGE_UPLOAD_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        changePassword: payload.data
      };
    case `${profileTypes.SET_PROFILE_IMAGE_UPLOAD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        changePassword: null
      };

    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        pointHistoryAll: payload.data
      };
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_ALL_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        pointHistoryAll: null
      };

    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        pointHistoryCollected: payload.data
      };
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_COLLECTED_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        pointHistoryCollected: null
      };

    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        pointHistorySpent: payload.data
      };
    case `${profileTypes.GET_PROFILE_SCORE_HISTORY_SPENT_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        pointHistorySpent: null
      };

    case `${profileTypes.GET_PROFILE_USER_IMAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        profilePhoto: payload.data
      };

    case `${profileTypes.GET_PROFILE_USER_IMAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        profilePhoto: null
      };

    case `${profileTypes.REMOVE_IMAGE_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${profileTypes.REMOVE_IMAGE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data
      };

    case `${profileTypes.IMAGE_UPLOAD_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        responseImageUpload: payload.data
      };

    case `${profileTypes.IMAGE_UPLOAD_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        responseImageUpload: null
      };

    case `${profileTypes.GET_ORDER_LIST_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        orderList: payload.data
      };

    case `${profileTypes.GET_ORDER_LIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        orderList: []
      };

    case `${profileTypes.GET_USERS_SCORE_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        score: payload.data
      };

    case `${profileTypes.GET_USERS_SCORE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        score: null
      };

    case `${profileTypes.GET_TENANT_MENU_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        tenantMenu: payload.data
      };

    case `${profileTypes.GET_TENANT_MENU_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload.data,
        tenantMenu: null
      };

    case `${profileTypes.GET_MONTHLY_PERFORMANCE_FULFILLED}`:
      return {
        ...state,
        monthlyBuy: payload,
        isLoading: false
      };
    case `${profileTypes.GET_MONTHLY_PERFORMANCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_FULFILLED}`:
      return {
        ...state,
        monthlyIncome: payload,
        isLoading: false
      };
    case `${profileTypes.GET_MONTHLY_INCOME_PERFORMANCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_FULFILLED}`:
      return {
        ...state,
        productFound: payload,
        isLoading: false
      };
    case `${profileTypes.GET_PRODUCT_FOUND_PERFORMANCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_PERCENTAGE_PERFORMANCE_FULFILLED}`:
      return {
        ...state,
        percentage: payload,
        isLoading: false
      };
    case `${profileTypes.GET_PERCENTAGE_PERFORMANCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${profileTypes.GET_FOCUS_INFO_PERFORMANCE_FULFILLED}`:
      return {
        ...state,
        focusInfo: payload,
        isLoading: false
      };
    case `${profileTypes.GET_FOCUS_INFO_PERFORMANCE_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default reducer;
