import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { userAction, profileAction, forumAction } from '../../redux/modules';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from "react-bootstrap/Modal";

import "./styles.scss";

import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faHeart, faTimes, faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import 'moment/locale/tr';
moment.locale('tr');

const OldSubjects = () => {
  const dispatch = useDispatch();
  const forumAllTopics = useSelector(store => store.forum.forumAllTopics);
  const pageDescription = useSelector((store) => store.profile.pageDescription);

  const [lgShow, setLgShow] = useState(false);

  useEffect(() => {
    dispatch(forumAction.getForumAllTopics());
    dispatch(profileAction.getPageDescription('FORUM'));

    return () => {};
  }, [dispatch]);

  const handleOpenModal = (e) => {
    e.preventDefault();
    setLgShow(true);
  };

  const handleCloseModal = (e) => {
    e.preventDefault();
    setLgShow(false);
  };

  return (
    <div className="profile-page page-container">
      <Header></Header>
      <div className="forum-page">
        <Container>
          <Row className="justify-content-md-center mt-5">
            <Col xs={12} md={10}>
              <Row>
                <Col xs={12} lg={3}>
                  <div className="detail-page-categories mb-5 mb-lg-0">
                    <ul className="category-list">
                      <ul className="category-list">
                        <li>
                          <a href="/forum">
                            Bu Haftanın Konusu
                          </a>
                        </li>

                        <li className="active">
                          <a href="/forum-old-subjects">
                            Geçmiş Konular
                          </a>
                        </li>
                      </ul>
                    </ul>
                  </div>
                </Col>

                <Col xs={12} lg={9}>
                  <div className="global-grid-component ">
                    <Row>
                      <Col>
                        <div className="global-grid-page-title">
                          {pageDescription?.title && 
                            <h1>{pageDescription.title}</h1>
                          }

                          {pageDescription?.description && 
                            <div dangerouslySetInnerHTML={{ __html: pageDescription?.description }} />
                          } 
                        </div>

                        <div className="terms-of-use mt-3">
                          <a onClick={(e) => handleOpenModal(e)}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                            <span>Kullanım Koşulları</span>
                          </a>
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>

                        <div className="forum forum-old">
                          {forumAllTopics && forumAllTopics.content && forumAllTopics.content.length > 0
                            ? forumAllTopics.content.map((item) => {
                              return (
                                <div className="forum-content mb-5">
                                  <p>
                                    {item && item.title && item.title}
                                  </p>
                                  <p>
                                    {item && item.description && item.description}
                                  </p>

                                  <div className="forum-actions">
                                    <a href="#" className="forum-likes">
                                      <FontAwesomeIcon icon={faHeart} />
                                      <span>{item && item.likes && item.likes}</span>
                                    </a>

                                    <a href="#" className="forum-messages">
                                      <FontAwesomeIcon icon={faEye} />
                                      <span>{item && item.views && item.views}</span>
                                    </a>
                                  </div>
                                </div>
                              );
                            })
                            : null}
                        </div>

                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        size="lg"
        show={lgShow}
        className="magazine-modal"
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <a
          onClick={(e) => handleCloseModal(e)}
          className="magazine-modal-close-button"
        >
          <FontAwesomeIcon icon={faTimes} />
        </a>
        <Modal.Header>
          <h3 className="m-0 p-0 text-lg">Bizim Meydan Kullanım koşulları</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="m-0 p-0">
            Bu sayfayı kullandığınızda ilgili koşulları maddeleri otomatikmen kabul etmiş olursunuz!
          </p>
        </Modal.Body>
      </Modal>

      <Footer></Footer>
    </div>
  );
};

export default OldSubjects;
