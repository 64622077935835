import React, { useState, useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authAction } from "../src/redux/modules";

import Router from "./pages/Router";
import { useHandleRouteWhenAuth } from "./hooks";

const App = () => {
    const dispatch = useDispatch();

    const rgbToHex = (r, g, b) => {
        return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }
    
    // Example Usage
    // rgbToHex(227, 242, 253); // #E3F2FD

    const hexToRgb = (hex) => {
        // '#' işaretini kaldırıyoruz
        let cleanedHex = hex.replace(/^#/, '');
      
        // Eğer hex kodu üç basamaklıysa (örneğin #FFF), bunu altı basamaklı hale getiriyoruz
        if (cleanedHex.length === 3) {
          cleanedHex = cleanedHex.split('').map(c => c + c).join('');
        }
      
        // Hex'i RGB formatına çeviriyoruz
        let r = parseInt(cleanedHex.slice(0, 2), 16);
        let g = parseInt(cleanedHex.slice(2, 4), 16);
        let b = parseInt(cleanedHex.slice(4, 6), 16);
      
        return `${r}, ${g}, ${b}`;
    }

    // Example Usage
    // hexToRgb('#E3F2FD');

    useEffect(() => {
        let siteURL = window.location.origin;
    
        // Eğer URL http ile başlıyorsa ve localhost ise, https ile değiştir
        if (siteURL.startsWith("http://") && window.location.hostname === "localhost") {
            siteURL = siteURL.replace("http://", "https://");
        }
    
        // API'den login ayarlarını alıyoruz
        dispatch(authAction.getLoginSettings(siteURL))
            .then(res => {
                if (res.isHttpSuccess) {
                    const { data } = res;
    
                    // Gelen renkleri CSS değişkenleri olarak güncelliyoruz
                    const colors = {
                        '--bs-blue-lighter-rgb': hexToRgb(data.blueLighter),
                        '--bs-blue-light-rgb': hexToRgb(data.blueLight),
                        '--bs-blue-rgb': hexToRgb(data.blue),
                        '--bs-blue-dark-rgb': hexToRgb(data.blueDark),
    
                        '--bs-blue-lighter': data.blueLighter,
                        '--bs-blue-light': data.blueLight,
                        '--bs-blue': data.blue,
                        '--bs-blue-dark': data.blueDark,
                    };

                    // Renk değişkenlerini güncelle
                    Object.keys(colors).forEach((key) => {
                        document.documentElement.style.setProperty(key, colors[key]);
                    });
    
                    // Gelen `pageTitle` değerini `<title>` etiketine ekle
                    if (data.pageTitle) {
                        document.title = data.pageTitle;
                    }
    
                    // `metaDescription` alanını `<meta name="description">` olarak güncelle
                    if (data.metaDescription) {
                        let metaDescriptionTag = document.querySelector('meta[name="description"]');
                        
                        // Eğer meta description tag yoksa yeni bir tane oluştur
                        if (!metaDescriptionTag) {
                            metaDescriptionTag = document.createElement('meta');
                            metaDescriptionTag.name = "description";
                            document.head.appendChild(metaDescriptionTag);
                        }
    
                        // Meta description içeriğini güncelle
                        metaDescriptionTag.content = data.metaDescription.replace(/<meta name='description' content='(.*?)' \/>/, '$1');
                    }
    
                    // `favicon` alanını güncelle, favicon tag'ini kontrol et ve güncelle
                    if (data.favicon) {
                        let faviconLink = document.querySelector('link[rel="icon"]');
    
                        // Eğer favicon yoksa yeni bir tane oluştur
                        if (!faviconLink) {
                            faviconLink = document.createElement('link');
                            faviconLink.rel = "icon";
                            document.head.appendChild(faviconLink);
                        }
    
                        // Favicon URL'sini güncelle
                        faviconLink.href = data.favicon;
                    }
                }
            })
            .catch(err => {
                console.log("Hata: ", err);
            });
    }, [dispatch]);


    // useEffect(() => {
    //     // Renkleri güncellemek için CSS değişkenlerini JavaScript ile güncelliyoruz
    //     document.documentElement.style.setProperty('--bs-blue-lighter-rgb', '227, 242, 253');
    //     document.documentElement.style.setProperty('--bs-blue-light-rgb', '25, 118, 210');  
    //     document.documentElement.style.setProperty('--bs-blue-rgb', '27, 97, 172');        
    //     document.documentElement.style.setProperty('--bs-blue-dark-rgb', '13, 71, 161');   

    //     document.documentElement.style.setProperty('--bs-blue-lighter', '#E3F2FD');
    //     document.documentElement.style.setProperty('--bs-blue-light', '#1976D2');  
    //     document.documentElement.style.setProperty('--bs-blue', '#1B61AC');        
    //     document.documentElement.style.setProperty('--bs-blue-dark', '#0D47A1');   
    // }, []);

    useHandleRouteWhenAuth();
    return <Router />;
}

export default App;