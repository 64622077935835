import * as forumTypes from './forum.type';
import { API } from '../../../services/http.service';

const REQUEST_URL = '';

// Get Forum All Topics
export const getForumAllTopics = id => async dispatch => {
  dispatch({ type: forumTypes.GET_FORUM_ALL_TOPICS_PENDING });

  try {
    let response = null;

    if (id) {
      response = await API.get(`${REQUEST_URL}/forum/get/all/topics${id}`);
    } else {
      response = await API.get(`${REQUEST_URL}/forum/get/all/topics`);
    }

    dispatch({
      type: forumTypes.GET_FORUM_ALL_TOPICS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: forumTypes.GET_FORUM_ALL_TOPICS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Get Forum All Topics
export const getForumWeeklyTopic = () => async dispatch => {
  dispatch({ type: forumTypes.GET_FORUM_WEEKLY_TOPIC_PENDING });

  try {
    const response = await API.get(`${REQUEST_URL}/forum/get/weekly/topic`);

    dispatch({
      type: forumTypes.GET_FORUM_WEEKLY_TOPIC_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: forumTypes.GET_FORUM_WEEKLY_TOPIC_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Get Forum Comments
export const getForumComments = id => async dispatch => {
  dispatch({ type: forumTypes.GET_FORUM_COMMENTS_PENDING });

  try {
    const data = {
      topicId: id,
      commentStatus: 'APPROVED',
    };

    const response = await API.post(`${REQUEST_URL}/forum/get/all/comments/topic/for/user`, data);

    dispatch({
      type: forumTypes.GET_FORUM_COMMENTS_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: forumTypes.GET_FORUM_COMMENTS_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};

// Set Forum Comment
export const setForumCommentById = (id, description) => async dispatch => {
  dispatch({ type: forumTypes.SET_FORUM_COMMENT_BY_ID_PENDING });

  try {
    const data = {
      topicId: id,
      description: description,
    };

    const response = await API.post(`${REQUEST_URL}/forum/save/comment`, data);

    dispatch({
      type: forumTypes.SET_FORUM_COMMENT_BY_ID_FULFILLED,
      payload: response,
    });
    return Promise.resolve(response);
  } catch (error) {
    dispatch({
      type: forumTypes.SET_FORUM_COMMENT_BY_ID_REJECTED,
      payload: error,
    });
    return Promise.reject(error);
  }
};