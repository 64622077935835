import React from "react";

const ProductFound = ({ data }) => {
  data?.data?.forEach(item => {
    if (typeof item.percentage === "string") {
      item.percentage = parseFloat(item.percentage).toFixed(2);
    }
  });

  console.log("ProductFound data: ", data);

  return (
    <div className="flex justify-center items-center">
      {data?.data?.length > 0
        ? data?.data?.map(item => (
            <div
              className="!m-2 flex flex-col justify-center items-center"
              key={item.style}
            >
              {item.icon && item.style && (
                <img
                  src={item.icon}
                  alt={item.style}
                  className="w-full max-w-[150px] img-fluid"
                />
              )}

              {item.icon && !item.style && (
                <img
                  src={item.icon}
                  className="w-full max-w-[150px] img-fluid"
                  alt=""
                />
              )}

              {item.style && (
                <h3 className="!mx-2 !mt-3 !mb-1">{item.style}</h3>
              )}

              {item.percentage && (
                <p className="!m-0">
                  + % {parseFloat(item.percentage).toFixed(1)}
                </p>
              )}
            </div>
          ))
        : "Data bulunamadı!"}
    </div>
  );
};

export default ProductFound;
