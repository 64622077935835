import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { userAction } from "../../redux/modules";
import { homeAction } from "../../redux/modules";

// Header - Footer
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// Fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Styles
import "./styles.scss";

const Leaderboard = () => {
  const dispatch = useDispatch();

  const [leaderboard, setLeaderboard] = useState([]);

  const getLeaderboard = () => {
    dispatch(homeAction.getLeaderboard()).then((e) => {
      if (e.isHttpSuccess) {
        setLeaderboard(e.data.boardEmployeeDTOList);
      }
    });
  };

  useEffect(() => {
    getLeaderboard();
    return () => {};
  }, [dispatch]);

  const topThree = leaderboard?.slice(0, 3);

  const reorderedTopThree = [
    // Reorder the top three items to display them in the correct style
    topThree[1],
    topThree[0],
    topThree[2],
  ];

  const indexHelper = (index) => {
    if (index === 0) {
      return 2;
    } else if (index === 1) {
      return 1;
    } else if (index === 2) {
      return 3;
    }
  };

  return (
    <>
      <Header />
      <section className="leaderboard-section">
        <Row className="justify-content-center">
          <Col xs={12} xl={8}>
            <div className="leaderboard-arrangement">
              <div className="leaderboard-header">
                <h3 className="leaderboard-title">Sıralama</h3>
              </div>

              <div className="leaderboard-content">
                <div className="leaderboard-winners">
                  {reorderedTopThree.map((item, index) => (
                    <div
                      key={index}
                      className={`winner-container winner-${indexHelper(
                        index
                      )}`}
                    >
                      {item?.icon ? 
                        <img
                          className="winner-image"
                          src={item?.icon}
                          alt="winner"
                        /> : 
                        <div className="m-auto shadow-sm rounded-circle bg-gray d-flex flex-col justify-content-center align-items-center" style={{ height: 48, width: 48 }}>
                          <FontAwesomeIcon
                            icon={faUser}
                            className="text-gray-special"
                            style={{ width: 24, height: 24, color: '#fff' }}
                          />
                        </div>
                      }
                      <p className="winner-name">{item?.employeeFullName}</p>
                      <p className="winner-points">{item?.value} Puan</p>
                    </div>
                  ))}
                </div>

                <div className="leaderboard-others">
                  <table className="leaderboard-table">
                    <tbody>
                      {leaderboard?.map((item, index) => (
                        <tr key={index}>
                          <td className="other-rank">
                            <div className="d-flex align-items-center">
                              {item.icon ? 
                                <img src={item?.icon} alt="" style={{ width: '36px', height: '36px', borderRadius: '50%', marginRight: '8px'}} />
                              : <div className="shadow-sm rounded-circle bg-gray d-flex flex-col justify-content-center align-items-center" style={{ height: 36, width: 36, marginRight: 8 }}>
                                <FontAwesomeIcon
                                  icon={faUser}
                                  className="text-gray-special"
                                  style={{ width: 24, height: 24, color: '#fff' }}
                                />
                              </div>
                              }
                              <span>{index + 1}. {item?.employeeFullName}</span>
                            </div>
                            <p>{item?.value} Puan</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <Footer />
    </>
  );
};

export default Leaderboard;
